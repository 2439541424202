import { Button, TextInput } from '~/components'
import env, { storeEnv } from '~/env'
import { useStore } from '~/store/store'

const E2EToggles = () => {
    const setAuth = useStore(state => state.user.actions.setAuth)
    const setDipsApiAccessToken = useStore(state => state.user.actions.setDipsApiAccessToken)

    return (
        <>
            <div className="flex cursor-pointer items-center">
                pkce authorization url (non-empty means use PKCE)
                <TextInput
                    defaultValue={env.VITE_PKCE_AUTHORIZE_URL}
                    className="cursor-pointer"
                    onChange={e => {
                        storeEnv({ VITE_PKCE_AUTHORIZE_URL: String(e.target.value) })
                    }}
                    data-test="#flag-VITE_PKCE_AUTHORIZE_URL"
                />
            </div>
            <div className="flex cursor-pointer items-center">
                <Button
                    onClick={() => {
                        setAuth({ accessToken: 'invalid-access-token' })
                    }}
                    data-test="invalidate-api-token-button"
                >
                    Invalidate API token
                </Button>
            </div>
            <div className="flex cursor-pointer items-center">
                <Button
                    onClick={() => {
                        setDipsApiAccessToken({ dipsApiAccessToken: 'invalid-access-token', dipsApiAccessTokenIsFromPKCE: false, idTokenPKCE: undefined })
                    }}
                    data-test="invalidate-dips-token-button"
                >
                    Invalidate DIPS token
                </Button>
            </div>
        </>
    )
}

export default E2EToggles
