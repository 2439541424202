import { SurgeryMetadatumCreate } from '~/clients/derived-api-types'
import { createSurgeryMetadata, partialUpdateSurgeryMetadata } from '~/store/di-entity.api'
import { SurgeryMetadata } from '~/store/selectors'

let timerId: NodeJS.Timeout | undefined = undefined

type Payload = Omit<SurgeryMetadatumCreate, 'checklist_items'> // we use patient statuses instead, e.g. patient_ready etc.
type Metadata = Omit<Payload, 'booking_id'>

export function useSaveSurgeryMetadata(bookingId: string, existingItem: SurgeryMetadata | undefined): (metadata: Metadata) => void {
    function saveSurgeryMetadata(payload: Payload) {
        if (!existingItem) {
            void createSurgeryMetadata(payload)
        } else {
            void partialUpdateSurgeryMetadata(existingItem.id, payload)
        }
    }

    return metadata => {
        const payload: Payload = { booking_id: bookingId, ...metadata }

        // Add debounce for comment
        if ('comment' in payload && typeof payload.comment === 'string') {
            payload.comment = payload.comment.trim()

            clearTimeout(timerId)

            timerId = setTimeout(() => saveSurgeryMetadata(payload), 200)
        } else {
            saveSurgeryMetadata(payload)
        }
    }
}
