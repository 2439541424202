import { ReactNode } from 'react'

import { CloseOutlined } from '~/icons'

type TagType = 'filled' | 'outlined' | 'none'

type Props = { type: TagType; children?: ReactNode; color?: string; icon?: ReactNode; onRemove?: () => void }

const TYPES_STYLE = {
    filled: 'bg-diBlue-500 text-white border-diBlue-500',
    outlined: 'text-diBlue-500 border-diBlue-500',
    none: '',
}

// Consider renaming to a more generic component
export const PractitionerTag = ({ type = 'filled', children, icon, onRemove }: Props) => {
    return (
        // I recommend switching to built in template strings for readability and removing dependency on clsx
        <div className={`inline-flex items-center justify-between rounded border px-2 py-0.5 font-semibold ${TYPES_STYLE[type]}`}>
            {icon && icon}

            <div className="ml-0.5 text-xs">{children}</div>

            {onRemove && (
                <div onClick={onRemove} className="ml-1.5 cursor-pointer">
                    <CloseOutlined className="h-4 w-4" />
                </div>
            )}
        </div>
    )
}
