import { AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'

import { RadioButtons } from '~/components'
import { useStore } from '~/store/store'

import { BookingDrawerAvailability } from './BookingDrawerAvailability'
import { BookingDrawerBookings } from './BookingDrawerBookings'
import { BookingDrawerHeader } from './BookingDrawerHeader'
import { Comment } from './Comment'

type FilterTypes = 'availability' | 'bookings' | 'all'
type FilterOption = { label: string; value: FilterTypes }
const listOptions: FilterOption[] = [
    { label: 'Alle', value: 'all' },
    { label: 'Bestilte Operasjoner', value: 'bookings' },
    { label: 'Ledige tider', value: 'availability' },
]
export const BookingDrawer = () => {
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const [listFilter, setListFilter] = useState<FilterTypes>('all')
    const scrollRef = useRef<HTMLDivElement | null>(null)

    // This key makes sure that the BookingDrawer component state is reset when the user clicks on a different date/location
    const key = useMemo(() => JSON.stringify(drawerProps), [drawerProps])

    // Reset the scroll position when the user clicks on a different date/location
    // This makes sure we always trigger the IntersectionObserver in the BookingDrawerAvailability component
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0)
        }
    }, [drawerProps])

    return (
        <div className="h-full w-[30rem] overflow-y-auto overflow-x-hidden" data-test="BookingDrawer" ref={scrollRef} key={key}>
            <div className="sticky top-0 bg-white">
                <BookingDrawerHeader />
                <Comment />
            </div>
            <div className="flex flex-col gap-3 px-4 py-3">
                <RadioButtons options={listOptions} onChange={selected => setListFilter(selected)} value={listFilter} />
                <AnimatePresence initial={false}>
                    {(listFilter === 'all' || listFilter === 'bookings') && <BookingDrawerBookings key="booked" />}
                    {(listFilter === 'all' || listFilter === 'availability') && drawerProps?.showAvailability && <BookingDrawerAvailability key="available" />}
                </AnimatePresence>
            </div>
        </div>
    )
}
