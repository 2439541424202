import { useEffect, useMemo } from 'react'

import { ConfirmFn } from '~/components'
import { selectGetPractitionerScheduleLocations, selectGetPractitionerSchedules, selectGetPractitionerScheduleStatuses } from '~/store/selectors'
import { useStore } from '~/store/store'

import { useDeleteStatusesAndLocations, useToggleStatuses } from '../hooks'
import { DatePractitionerCell } from '../utils'

type Props = {
    selectedCells: DatePractitionerCell[]
    confirm: ConfirmFn
}

export const useCreateKeyboardShortcuts = ({ selectedCells, confirm }: Props) => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)
    const statuses = useStore(state => state.di.entities.practitionerStatusDefinitions)

    const practitionerSchedules = selectedCells.map(({ date, practitionerId }) => getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId))

    const allScheduleStatusesByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule?.id ?? 0))
    const allScheduleLocationsByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleLocations.byPractitionerScheduleId(schedule?.id ?? 0))

    const { toggleStatus } = useToggleStatuses(selectedCells, allScheduleStatusesByCell)
    const { deleteStatusesAndLocations } = useDeleteStatusesAndLocations(allScheduleStatusesByCell, allScheduleLocationsByCell, confirm)

    const keyCodeToFn: Record<string, () => Promise<void>> = useMemo(() => {
        const shortcuts = Object.fromEntries(
            statuses.allIds
                .map(statusCode => {
                    const status = statuses.byId[statusCode]
                    const keyboard_shortcut = status?.keyboard_shortcut
                    if (!keyboard_shortcut) return null
                    return [`Key${keyboard_shortcut}`, () => toggleStatus(status.code)]
                })
                .filter(Boolean)
        )
        shortcuts['Backspace'] = deleteStatusesAndLocations
        shortcuts['Delete'] = deleteStatusesAndLocations
        return shortcuts
    }, [statuses, deleteStatusesAndLocations, toggleStatus])

    useEffect(() => {
        function listener(e: KeyboardEvent) {
            const keyCode = e.code
            if (selectedCells.length === 0) return

            if (keyCode in keyCodeToFn) {
                void keyCodeToFn[keyCode]?.()
            }
        }

        document.addEventListener('keyup', listener)

        return () => {
            document.removeEventListener('keyup', listener)
        }
    }, [keyCodeToFn, selectedCells.length, toggleStatus])
}
