import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FilterDropdown, PractitionerFilter, Show } from '~/components'
import { selectWaitingListFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { AsaFilter } from './AsaFilter'
import { ClearedFilter } from './ClearedFilter'
import { ConfirmedFilter } from './ConfirmedFilter'
import { DayFilter } from './DayFilter'
import { DiagnosisGroupFilter } from './DiagnosisGroupFilter'
import { PrioritizationFilter } from './PrioritizationFilter'
import { ShortNoticeFilter } from './ShortNoticeFilter'
import { SurgeryCategoryFilter } from './SurgeryCategoryFilter'

const PatientStatusFilterWrapper = ({ filter }: { filter: ReactNode }) => <div className="flex w-full flex-wrap justify-start gap-2">{filter}</div>

type GroupedFiltersProps = {
    children: ReactNode
    title: string
    direction?: 'row' | 'col'
}

const GroupedFilters = ({ children, title, direction = 'row' }: GroupedFiltersProps) => (
    <div className="px-4 pb-4 pt-2">
        <p className="w-full pb-2">{title}</p>
        <div className={`flex gap-2 ${direction === 'row' ? 'flex-row' : 'flex-col'}`}>{children}</div>
    </div>
)

export const Filters = () => {
    const { removeSingleFilter, emptyFilters } = useStore(state => state.appFilters.actions)
    const filters = useStore(selectWaitingListFilters)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)
    const installFiltersFromURL = useStore(state => state.appFilters.actions.installFiltersFromURL)
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const isAllOrScheduledView = activeView === '/waiting-list/all' || activeView === '/waiting-list/scheduled'
    const searchUrl = useLocation().search

    useEffect(() => installFiltersFromURL(searchUrl), [installFiltersFromURL, searchUrl])

    const handleClearFilters = () => {
        emptyFilters()
        setSelectedPage(1)
    }

    const handleOnDisplayItemRemove = (id: string) => {
        removeSingleFilter(id)
        setSelectedPage(1)
    }

    return (
        <FilterDropdown clearFilters={handleClearFilters} onDisplayItemRemove={handleOnDisplayItemRemove} displayItems={filters}>
            <GroupedFilters title="Pasientstatus" direction="col">
                <PatientStatusFilterWrapper filter={<ClearedFilter />} />
                <Show condition={isAllOrScheduledView}>
                    <PatientStatusFilterWrapper filter={<ConfirmedFilter />} />
                </Show>
            </GroupedFilters>
            <GroupedFilters title="Merknader">
                <ShortNoticeFilter />
                <PrioritizationFilter />
            </GroupedFilters>
            <AsaFilter />
            <PractitionerFilter />
            <DiagnosisGroupFilter />
            <SurgeryCategoryFilter />
            <DayFilter />
        </FilterDropdown>
    )
}
