import { Dayjs } from 'dayjs'
import { motion } from 'framer-motion'
import { ChangeEvent, useEffect, useState } from 'react'

import { AgeGroup, Button, Spinner, Tag } from '~/components'
import { ExclamationCircleOutlined, PhoneIconOutlined } from '~/icons'
import { SurgeryForPresentation } from '~/utils/dips'
import { getNewDateWithTime } from '~/utils/extendedDayjs/helpers/getNewDateWithTime'

import { BookingFormInput } from './BookingFormInput'
import { BookingInfo } from './BookingInfo'

interface Props extends SurgeryForPresentation {
    isLoading: boolean
    defaultCheckInTime: string
    defaultKnifeTime: string
    defaultError?: string
    onSubmit: (checkInTime: string, startTime: string) => void
    onClose: () => void
    bookingDate: Dayjs
}

const labelInputStyles = 'grid max-w-[332px] grid-cols-[1fr_132px] items-center justify-between text-gray-500 gap-4'

const errorStyles =
    'absolute bottom-[-36px] flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white'

export const BookingForm = ({
    operation,
    patient,
    practitioners,
    location,
    bookingDate,
    comment,
    displayDate,
    defaultCheckInTime,
    defaultKnifeTime,
    isLoading,
    asa,
    dayOvernight,
    patientAgeGroup,
    isShortNotice,
    defaultError,
    onSubmit,
    onClose,
}: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>(defaultCheckInTime)
    const [checkInTimeError, setCheckInTimeError] = useState<string | undefined>()
    const [startTime, setStartTime] = useState<string>()
    const [hasInitialFormData, setHasInitialFormData] = useState(false)
    const [startTimeError, setStartTimeError] = useState<string | undefined>()
    const [multiInputError, setMultiInputError] = useState<string | undefined>(defaultError)

    useEffect(() => {
        const initialFormData = defaultCheckInTime || defaultKnifeTime

        if (!hasInitialFormData && initialFormData) {
            setCheckInTime(defaultCheckInTime)
            setHasInitialFormData(true)
        }
    }, [hasInitialFormData, defaultCheckInTime, defaultKnifeTime])

    const resetErrors = () => {
        setCheckInTimeError(undefined)
        setStartTimeError(undefined)
        setMultiInputError(undefined)
    }

    const handleSubmit = () => {
        if (multiInputError) {
            return
        }
        if (!checkInTime || !startTime) {
            setMultiInputError('Stue inn og Oppmøtetid må fylles ut')
            return
        }
        onSubmit(checkInTime, startTime)
    }

    const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value)

        const checkin = getNewDateWithTime(bookingDate, checkInTime)
        const start = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setStartTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    const handleCheckinTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckInTime(e.target.value)

        if (!startTime) {
            return
        }

        const start = getNewDateWithTime(bookingDate, startTime ?? '00:00')

        const checkin = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setCheckInTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    return (
        <>
            <p className="text-base font-semibold">Om pasienten og inngrepet</p>
            <dl className="relative mb-5 grid w-[585px] justify-between gap-6">
                <BookingInfo label="Operasjon">{operation}</BookingInfo>
                <BookingInfo label="Merknad">
                    <div className="flex gap-2">
                        {asa && (
                            <Tag color="ASA" textSize="sm">
                                {asa}
                            </Tag>
                        )}
                        {dayOvernight && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                {dayOvernight.icon}
                                <p>{dayOvernight.label}</p>
                            </div>
                        )}
                        {isShortNotice && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                <PhoneIconOutlined />
                                <p>Kort Varsel</p>
                            </div>
                        )}
                        {patientAgeGroup && patientAgeGroup.age_group_code === 'child' && <AgeGroup ageGroupCode={patientAgeGroup?.age_group_code} />}
                    </div>
                </BookingInfo>
                <BookingInfo label="Pasient">{patient}</BookingInfo>
                <BookingInfo label="Kirurg">
                    {practitioners
                        .filter(prac => prac.name !== undefined)
                        .map((prac, i) => (
                            <dd key={prac.name}>{`${i > 0 ? ',' : ''}${prac.short_name}`}</dd>
                        ))}
                </BookingInfo>
                <BookingInfo label="Stue">{location}</BookingInfo>
                <BookingInfo label="Dato">
                    <dd className="capitalize">{displayDate}</dd>
                </BookingInfo>
                <BookingInfo label="Kommentar">{comment}</BookingInfo>
                <div className="grid grid-cols-[120px_1fr] text-base">
                    <p className="text-gray-500">Tider</p>
                    <div className="flex flex-col gap-1">
                        <BookingFormInput
                            label="Oppmøtetid (klokkeslett)"
                            defaultValue={checkInTime}
                            onChange={handleCheckinTimeChange}
                            error={checkInTimeError}
                            id="check-in-time"
                        />
                        <BookingFormInput
                            label="Stue inn (klokkeslett)"
                            defaultValue={startTime}
                            onChange={handleStartTimeChange}
                            error={startTimeError}
                            id="start-time"
                        />
                        <p className={labelInputStyles}>
                            Knivtid
                            <div>&nbsp;{defaultKnifeTime}</div>
                        </p>
                    </div>
                </div>
                {multiInputError && (
                    <motion.p initial={{ opacity: 0, y: 16 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }} className={errorStyles}>
                        <ExclamationCircleOutlined height={20} />
                        {multiInputError}
                    </motion.p>
                )}
            </dl>

            <div className="mt-2 flex justify-end gap-2">
                <Button color="delete" onClick={onClose}>
                    Avbryt
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isLoading || !!startTimeError || !!checkInTimeError || !!multiInputError}
                    data-test={isLoading || !!startTimeError || !!checkInTimeError || !!multiInputError ? '' : 'confirm-button'}
                >
                    <span className="flex gap-2">{isLoading && <Spinner />}Planlegg operasjon</span>
                </Button>
            </div>
        </>
    )
}
