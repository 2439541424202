import {
    DownloadCodeListButton,
    DownloadDepartmentsButton,
    DownloadLocalizationsButton,
    DownloadSectionsButton,
    DownloadSwaggerButton,
    DownloadWardsButton,
} from './Buttons/DownloadButtons'

const DownloadPage = () => (
    <div className="mx-auto flex w-[500px] flex-col gap-2 p-5">
        <div className="text">This page is used for downloading configuration data. None of these downloads contain sensitive patient data.</div>
        <DownloadSwaggerButton label="ProcedurePlanningAPI" />
        <DownloadCodeListButton label="LocationTypes" codelistid={3297} />
        <DownloadCodeListButton label="SurgeryTypes" codelistid={2507} />
        <DownloadCodeListButton label="FinishCodeTypes" codelistid={11} />
        <DownloadCodeListButton label="PostponeCodeTypes" codelistid={1019} />
        <DownloadCodeListButton label="CancellationCodeTypes" codelistid={1115} />
        <DownloadLocalizationsButton label="Localizations" />
        <DownloadDepartmentsButton label="Departments" />
        <DownloadSectionsButton label="Sections" />
        <DownloadWardsButton label="Wards" />
    </div>
)

export default DownloadPage
