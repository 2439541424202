import { flip, offset } from '@floating-ui/react'
import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { BlockScheduleEditor, Popover } from '~/components'
import { Location } from '~/store/selectors'
import { isHoliday } from '~/utils/extendedDayjs'

import { ResolvedRule } from './ResolvedCell/ResolvedRule'

type Props = {
    date: Dayjs
    location: Location
}

export const Cell = ({ date, location }: Props) => {
    const [editorOpen, setEditorOpen] = useState(false)

    const closeModal = () => setEditorOpen(false)
    const openModal = () => setEditorOpen(true)

    if (isHoliday(date)) {
        return null
    }

    return (
        <>
            {editorOpen && <div className="absolute left-0 top-0 h-full w-full" onClick={closeModal} />}
            <Popover
                className="h-full cursor-pointer"
                middleware={[offset(10), flip()]}
                open={editorOpen}
                onOpenChange={setEditorOpen}
                trigger={
                    <div onClick={openModal} className="h-full p-2 hover:bg-slate-50">
                        <ResolvedRule date={date} location={location} />
                    </div>
                }
            >
                <BlockScheduleEditor date={date} location={location} onSubmit={closeModal} onRemove={closeModal} />
            </Popover>
        </>
    )
}
