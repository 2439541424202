import dayjs from 'dayjs'

// Cache to store the results of `isBefore` comparisons
const isBeforeCache = new Map<string, boolean>()

function getCacheKey(date1: dayjs.Dayjs, date2: dayjs.Dayjs): string {
    // Create a unique key for the two dates being compared
    return `${date1.toISOString()}_${date2.toISOString()}`
}

export function isBefore(date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean {
    const cacheKey = getCacheKey(date1, date2)

    // Check if result is in cache
    if (isBeforeCache.has(cacheKey)) {
        return isBeforeCache.get(cacheKey)!
    }

    // Compute the result and store it in the cache
    const result = date1.isBefore(date2)
    isBeforeCache.set(cacheKey, result)

    return result
}
