import { flip, offset } from '@floating-ui/dom'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { pages } from '~/app/routes'
import { CopyToClipboard, LinkButton, Popover, Show } from '~/components'
import env from '~/env'
import { DownOutlined, FeedbackOutlined, HelpOutlined, LinkOutlined, MoveOutlined } from '~/icons'
import { showUserFeedbackForm } from '~/sentry'
import { useStore } from '~/store/store'

import { getPKCELogoutUrl, pkceClearLocalStorage } from '../PKCEAuthenticationFlow'
import { DepartmentFilter } from './DepartmentFilter'

export const UserMenu = () => {
    const [showUserMenu, setShowUserMenu] = useState(false)

    const idTokenPKCE = useStore(state => state.user.idTokenPKCE)
    const dipsApiAccessTokenIsFromPKCE = useStore(state => state.user.dipsApiAccessTokenIsFromPKCE)
    const username = useStore(state => state.user.name)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const departments = useStore(state => state.di.entities.departments)

    const department = departmentKey !== 'all' ? departments.byId[departmentKey]?.name : 'Alle'
    const usesDepartmentFilter = !useLocation().pathname.startsWith(pages.DOWNLOAD.views.download.path)
    const logoutUrl = dipsApiAccessTokenIsFromPKCE ? getPKCELogoutUrl(idTokenPKCE) : env.VITE_API_BASE_URL + '/oauth/logout'

    return (
        <Popover
            className="flex"
            middleware={[offset(5), flip()]}
            open={showUserMenu}
            onOpenChange={setShowUserMenu}
            trigger={
                <div
                    className="flex min-w-[280px] cursor-pointer items-center justify-between rounded border py-1 pl-3 pr-[6px] hover:border-diBlue-700"
                    onClick={() => setShowUserMenu(prev => !prev)}
                    data-test="user-menu-button"
                >
                    <div className="flex items-center gap-2" data-test="departments-filter-loaded">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-zinc-300 text-xs">{username && username[0]}</div>
                        <span>
                            {username} | <span className="text-sm font-normal text-neutral-400">{department}</span>
                        </span>
                    </div>
                    <DownOutlined className="h-5 w-5" />
                </div>
            }
        >
            <div className="flex flex-col" data-test="user-menu-popup">
                <Show condition={usesDepartmentFilter}>
                    <div className="border-b px-4 py-2">
                        <DepartmentFilter />
                    </div>
                </Show>
                <div className="border-b px-4 py-2">
                    <Show condition={env.VITE_ENABLE_SENTRY_USER_FEEDBACK}>
                        <div
                            className="flex cursor-pointer justify-between rounded p-2 hover:bg-diBlue-100"
                            onClick={() => void showUserFeedbackForm(username)}
                        >
                            <div className="flex items-center gap-1 font-normal">
                                <FeedbackOutlined /> Gi tilbakemelding
                            </div>
                        </div>
                    </Show>
                    <CopyToClipboard copyText="https://help.deepinsight.io">
                        <div className="flex cursor-pointer justify-between rounded p-2 hover:bg-diBlue-100">
                            <div className="flex items-center gap-1 font-normal">
                                <HelpOutlined />
                                Hjelp
                            </div>
                            <div className="flex items-center gap-1 font-medium">
                                <LinkOutlined />
                                Kopier lenke
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>
                <div className="px-4 py-2">
                    <LinkButton
                        href={logoutUrl}
                        data-test="logout-button"
                        size="custom"
                        color="custom"
                        className="flex items-center gap-1 p-2 font-normal hover:bg-diBlue-100"
                        onClick={dipsApiAccessTokenIsFromPKCE ? pkceClearLocalStorage : undefined}
                    >
                        <MoveOutlined />
                        Logg ut
                    </LinkButton>
                </div>
            </div>
        </Popover>
    )
}
