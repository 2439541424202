import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'

import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries, selectSurgeryTypeGroup } from '~/store/selectors'
import { isDayOvernightKey } from '~/store/slices/filterSlice'
import { StoreState } from '~/store/store'
import { getSurgeons } from '~/utils/dips'
import { isNullish } from '~/utils/guards'
import { needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { columns, FormattedWaitingListItem, isUnScheduledSurgery, WaitingListColumn, WaitingListItem } from '../shared/columns'
import { getSurgeryCategoryName, nullishDiagnosisGroupName } from '../shared/utils'
import { selectWaitingListFilterValues } from './waitingListFilters'

// Select waiting list items by department only (used for filter options)
export const selectAllWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, getUnScheduledSurgeries, getScheduledSurgeries) => {
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)

// Select waiting list items by department and view
const selectWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    (state: StoreState) => state.app.activeViews.WAITING_LIST,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, activeView, getUnScheduledSurgeries, getScheduledSurgeries) => {
        if (activeView === '/waiting-list/scheduled') {
            return getScheduledSurgeries.byDepartmentKey(departmentKey)
        } else if (activeView === '/waiting-list/unscheduled') {
            return getUnScheduledSurgeries.byDepartmentKey(departmentKey)
        }

        // Assumed 'All'
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)

function cleared(clearedValues: string[]) {
    return (item: WaitingListItem) => {
        const isPatientCleared = item.surgeryMetadata?.patient_ready
        const isUnscheduledSurgery = isUnScheduledSurgery(item)

        return (
            clearedValues.length === 0 ||
            (clearedValues.includes('Ny') && (isUnscheduledSurgery ? isNullish(isPatientCleared) : false)) ||
            (clearedValues.includes('Ikke klarert') && (isUnscheduledSurgery ? isPatientCleared === false : !isPatientCleared)) ||
            (clearedValues.includes('Klarert') && isPatientCleared)
        )
    }
}

function confirmed(confirmedValues: string[]) {
    return (item: WaitingListItem) => {
        const isPatientConfirmed = item.surgeryMetadata?.patient_confirmed

        return (
            confirmedValues.length === 0 ||
            (confirmedValues.includes('Ikke bekreftet') && !isPatientConfirmed) ||
            (confirmedValues.includes('Bekreftet') && isPatientConfirmed)
        )
    }
}

function dayOvernight(dayOvernightValues: string[]) {
    return (item: WaitingListItem) => {
        if (dayOvernightValues.length === 0) return true

        const nprCodeName = item.contact?.levelOfCareNpr?.nprCodeName

        // If valid value, then filter, otherwise include to not hide invalid values
        return isDayOvernightKey(nprCodeName) ? dayOvernightValues.includes(nprCodeName) : true
    }
}

// Apply filters to selectWaitingListItems
const selectFilteredWaitingListItems = createSelector(
    selectWaitingListItems,
    selectSurgeryTypeGroup,
    selectWaitingListFilterValues,
    (surgeries, getSurgeryTypeGroup, filterValues) => {
        const {
            shortNoticeValues,
            prioritizationValues,
            clearedValues,
            confirmedValues,
            asaValues,
            practitionerValues,
            diagnosisGroupValues,
            surgeryCategoryValues,
            dayOvernightValues,
        } = filterValues

        return surgeries
            .filter(item => shortNoticeValues.at(0) === undefined || item.surgeryMetadata?.patient_short_notice)
            .filter(item => prioritizationValues.at(0) === undefined || item.surgeryMetadata?.patient_prioritized)
            .filter(cleared(clearedValues))
            .filter(confirmed(confirmedValues))
            .filter(item => asaValues.length === 0 || asaValues.includes(item.surgeryOrderDetails?.asa ?? ''))
            .filter(
                item => practitionerValues.length === 0 || getSurgeons(item.surgeryResources).some(surgeon => practitionerValues.includes(surgeon.short_name))
            )
            .filter(item => diagnosisGroupValues.length === 0 || diagnosisGroupValues.includes(item.contact?.diagnosisGroupName ?? nullishDiagnosisGroupName))
            .filter(item => surgeryCategoryValues.length === 0 || surgeryCategoryValues.includes(getSurgeryCategoryName(item, getSurgeryTypeGroup) ?? ''))
            .filter(dayOvernight(dayOvernightValues))
    }
)

function transform(item: WaitingListItem) {
    const result: Record<string, unknown> = {}
    for (const [column, definitions] of Object.entries(columns)) {
        result[column] = {
            comparable: definitions.getComparable(item),
            formatted: definitions.format(item),
        }
    }

    return result as {
        [K in keyof typeof columns]: {
            formatted: ReturnType<(typeof columns)[K]['format']>
            comparable: ReturnType<(typeof columns)[K]['getComparable']>
        }
    }
}

export type TransformedWaitingListItem = ReturnType<typeof transform>

// Transform selectFilteredWaitingListItems
const selectTransformedWaitingListItems = createSelector(selectFilteredWaitingListItems, filteredItems => {
    return filteredItems.map(transform)
})

function containsNeedle(needle: string) {
    const filterTokens = needleToTokens(needle)

    return (item: TransformedWaitingListItem) => {
        return filterTokens.every(token =>
            Object.values(item).some(value =>
                isArray(value.formatted)
                    ? value.formatted.some(formatted => formatted.toLocaleLowerCase().indexOf(token) !== -1)
                    : value.formatted.toLocaleLowerCase().indexOf(token) !== -1
            )
        )
    }
}

// Apply search to selectTransformedWaitingListItems
const selectSearchedWaitingListItems = createSelector(
    selectTransformedWaitingListItems,
    (state: StoreState) => state.waitingList.pageRawNeedle,
    (filteredItems, pageRawNeedle) => {
        const needle = pageRawNeedle.toLowerCase()

        return filteredItems.filter(containsNeedle(needle))
    }
)

function getFormattedOnly(item: TransformedWaitingListItem) {
    const result: Record<string, unknown> = {}

    for (const key of Object.keys(item)) {
        result[key] = item[key as WaitingListColumn].formatted
    }

    return result as FormattedWaitingListItem
}

// Apply sorting to selectSearchedWaitingListItems and map to formatted items
export const selectSortedWaitingListItems = createSelector(
    selectSearchedWaitingListItems,
    (state: StoreState) => state.waitingList.columnSortedOn,
    (state: StoreState) => state.waitingList.sortOrder,
    (filteredItems, columnSortedOn, sortOrder) => {
        return orderBy(filteredItems, item => item[columnSortedOn].comparable, sortOrder).map(getFormattedOnly)
    }
)
