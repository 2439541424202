import { immer } from 'zustand/middleware/immer'

import { UserInfoResponse } from '~/clients/derived-api-types'
import env from '~/env'

import { Slice } from '../store'

// can be expanded to hold other user preferences such as 'defaultDepartment'
type UserSettings = {
    hideSuggestionsInfo: boolean
}

type State = {
    name: string | null
    issuer: UserInfoResponse['issuer'] | null
    apiAccessToken: string | null
    dipsApiAccessToken: string | null
    dipsApiAccessTokenIsFromPKCE: boolean
    idTokenPKCE?: string
    settings: UserSettings
}

const initialState: State = {
    name: null,
    issuer: null,
    apiAccessToken: null,
    dipsApiAccessToken: null,
    dipsApiAccessTokenIsFromPKCE: false,
    idTokenPKCE: undefined,
    settings: { hideSuggestionsInfo: false },
}

type Actions = {
    actions: {
        setDipsApiAccessToken: (args: {
            dipsApiAccessToken: State['dipsApiAccessToken']
            dipsApiAccessTokenIsFromPKCE: State['dipsApiAccessTokenIsFromPKCE']
            idTokenPKCE: State['idTokenPKCE']
        }) => void
        setAuth: (args: { name?: string | null; accessToken: string | null; issuer?: UserInfoResponse['issuer'] }) => void
        setHideSuggestionsInfo: (hideInfo: UserSettings['hideSuggestionsInfo']) => void
    }
}

export type UserSlice = {
    user: State & Actions
}

export const createUserSlice: Slice<UserSlice> = immer(set => ({
    user: {
        ...initialState,
        actions: {
            setHideSuggestionsInfo: hideInfo => {
                set(state => {
                    state.user.settings.hideSuggestionsInfo = hideInfo
                })
            },
            setDipsApiAccessToken: ({ dipsApiAccessToken, dipsApiAccessTokenIsFromPKCE, idTokenPKCE }) => {
                set(state => {
                    state.user.dipsApiAccessToken = dipsApiAccessToken
                    state.user.dipsApiAccessTokenIsFromPKCE = dipsApiAccessTokenIsFromPKCE
                    state.user.idTokenPKCE = idTokenPKCE
                })
            },
            setAuth: ({ name, accessToken, issuer }) => {
                set(state => {
                    state.user.apiAccessToken = accessToken
                    state.user.name = name ?? state.user.name
                    state.user.issuer = issuer ?? state.user.issuer

                    if (!env.VITE_PKCE_AUTHORIZE_URL) {
                        state.user.dipsApiAccessToken = accessToken
                        state.user.dipsApiAccessTokenIsFromPKCE = !accessToken
                    }
                })
            },
        },
    },
}))
