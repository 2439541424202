import clsx from 'clsx'
import { Fragment } from 'react'

import { Show, TextInput } from '~/components'
import env, { EnvKey, getEnvVarDiff, storeEnv } from '~/env'
import { getEntries } from '~/utils/object'

type EnvVarConfig = {
    inputType: 'boolean' | 'number' | 'string'
    label: string
}

const categories = [
    {
        title: 'Views',
        items: {
            VITE_FF_MASTER_SURGERY_SCHEDULER: { inputType: 'boolean', label: 'Enable master surgery scheduler (Kapasitet)' },
            VITE_FF_OPERATIONAL_PLANNER: { inputType: 'boolean', label: 'Enable operational planner (Planlegging)' },
            VITE_FF_SURGEON_SCHEDULER: { inputType: 'boolean', label: 'Enable surgeon scheduler (Bemanning)' },
            VITE_FF_ENTITY_EDITOR_IN_NAVIGATION: { inputType: 'boolean', label: 'Enable entity editor in navigation' },
            VITE_FF_PLANNING_PERIODS_TAB: { inputType: 'boolean', label: 'Enable planning periods tab' },
        },
    },
    {
        title: 'Waiting list',
        items: {
            VITE_WAITING_LIST_ITEMS_PER_PAGE: { inputType: 'number', label: 'Items per page' },
            VITE_FF_WAITING_LIST: { inputType: 'boolean', label: 'Enable waiting list' },
            VITE_FF_WAITING_LIST_MUTABLE_COMMENTS: { inputType: 'boolean', label: 'Enable mutable comments' },
            VITE_WAITINGLIST_HIDE_ASA_FOR_DEPARTMENT_IDS: { inputType: 'string', label: 'Hide ASA for Department IDs (comma separated)' },
            VITE_DIPS_TREATMENT_LOCATION_ID: { inputType: 'number', label: 'DIPS Treatment Location ID' },
        },
    },
    {
        title: 'Operational planner',
        items: {
            VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER: { inputType: 'boolean', label: 'Show waiting list in the availability drawer' },
            VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON: { inputType: 'boolean', label: 'Show waiting list booking button' },
            VITE_FF_OPERATIONAL_PLANNER_BOOKING_SEARCH_SURGERIES: { inputType: 'boolean', label: 'Show button for searching for surgeries to book' },
        },
    },
    {
        title: 'Debugging',
        items: {
            VITE_STRICT_MODE: { inputType: 'boolean', label: 'Enable React strict mode' },
            VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_API: { inputType: 'boolean', label: 'Enable Sentry trace propagation to API' },
            VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_DIPS: { inputType: 'boolean', label: 'Enable Sentry trace propagation to DIPS' },
            VITE_TODAY: { inputType: 'string', label: 'Current date' },
            VITE_SEGMENT_HOSTNAME: { inputType: 'string', label: 'hostname for segment' },
            VITE_SEGMENT_TENANT_NAME: { inputType: 'string', label: 'tenant name in segment' },
            VITE_SENTRY_DSN: { inputType: 'string', label: 'sentry endpoint' },
        },
    },
] satisfies { title: string; items: Partial<Record<EnvKey, EnvVarConfig>> }[]

const EnvironmentVariables = () => {
    const diff = getEnvVarDiff()

    return (
        <div>
            <div className="pb-2 text-lg font-bold">NB! Requires a page refresh after applying.</div>
            <div className="flex flex-row gap-2">
                {categories.map(({ title, items }) => {
                    return (
                        <div key={title} className="border p-2">
                            <div className="text-center font-semibold">{title}</div>
                            <div className="grid grid-cols-2 gap-2" style={{ gridTemplateColumns: 'auto 1fr' }}>
                                {getEntries(items).map(([key, envVarConfig]) => {
                                    const id = `flag-${key}`

                                    return (
                                        <Fragment key={id}>
                                            <label key={key} className="flex cursor-pointer items-center gap-2" htmlFor={id}>
                                                <span
                                                    className={clsx({
                                                        'font-bold text-diBlue-500': diff[key] !== undefined,
                                                    })}
                                                >
                                                    {envVarConfig?.label}
                                                </span>
                                            </label>
                                            <div className="flex cursor-pointer items-center">
                                                <Show condition={envVarConfig?.inputType === 'boolean'}>
                                                    <input
                                                        id={id}
                                                        type="checkbox"
                                                        defaultChecked={env[key] as boolean}
                                                        className="cursor-pointer"
                                                        onChange={e => {
                                                            storeEnv({ [key]: Boolean(e.target.checked) })
                                                        }}
                                                    />
                                                </Show>
                                                <Show condition={envVarConfig?.inputType === 'number'}>
                                                    <TextInput
                                                        id={id}
                                                        type="number"
                                                        defaultValue={env[key] as number}
                                                        className="cursor-pointer"
                                                        onChange={e => {
                                                            storeEnv({ [key]: String(e.target.value) })
                                                        }}
                                                    />
                                                </Show>
                                                <Show condition={envVarConfig?.inputType === 'string'}>
                                                    <TextInput
                                                        id={id}
                                                        defaultValue={env[key] as string}
                                                        className="cursor-pointer"
                                                        onChange={e => {
                                                            storeEnv({ [key]: String(e.target.value) })
                                                        }}
                                                    />
                                                </Show>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EnvironmentVariables
