import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/react'
import clsx from 'clsx'
import { ReactNode, RefObject, useRef } from 'react'

import { CloseOutlined } from '~/icons'

import { fromCellId, Id, TableCell } from './utils'

type Props = {
    tableRef: RefObject<HTMLTableElement>
    modal: (tableCells: TableCell<{ id: Id }, { id: Id }>[]) => ReactNode

    selectedCells: Set<string>
    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
    handleCloseIconClick: () => void
}

function getLatestSelectedCellEl(selectedCells: Set<string>, tableRef: HTMLTableElement | null) {
    const latestSelectedCell = Array.from(selectedCells).at(-1)
    return tableRef?.querySelector<HTMLTableCellElement>(`[data-cell="${latestSelectedCell}"]`)
}

export const Modal = ({ tableRef, modal, selectedCells, isModalOpen, handleCloseIconClick, setIsModalOpen }: Props) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const modalElement = modalRef.current

    const cellElement = getLatestSelectedCellEl(selectedCells, tableRef.current)
    const cells = Array.from(selectedCells).map(fromCellId)

    const { x, y } = useFloating({
        placement: 'bottom-start',
        strategy: 'absolute',
        middleware: [
            offset(({ rects }) => ({
                // to make the modal appear at the corner of the cell
                alignmentAxis: rects.reference.width + 2,
            })),
            shift({ padding: 30 }),
            flip(),
            size({
                apply({ availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: `${Math.max(50, availableHeight)}px`,
                    })
                },
            }),
        ],
        elements: {
            reference: cellElement,
            floating: modalElement,
        },
        whileElementsMounted: (...args) =>
            autoUpdate(...args, {
                layoutShift: false,
                ancestorScroll: false,
            }),
    })

    return (
        <div className={clsx('absolute left-0 top-0 z-40 h-full w-full overflow-hidden', { hidden: !isModalOpen })} onClick={() => setIsModalOpen(false)}>
            <div
                ref={modalRef}
                onClick={e => e.stopPropagation()}
                style={{ left: `${x}px`, top: `${y}px` }}
                className={clsx('absolute z-40 h-fit overflow-auto rounded bg-white drop-shadow-2xl', {
                    invisible: !isModalOpen,
                })}
            >
                <CloseOutlined onClick={handleCloseIconClick} className="absolute right-2.5 top-2.5 cursor-pointer rounded-full hover:bg-blue-900/40" />
                {modal(cells)}
            </div>
        </div>
    )
}
