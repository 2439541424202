import { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'

import { HeroDayPicker } from '~/components/HeroDayPicker/HeroDayPicker'
import { HeroPopover } from '~/components/HeroLibrary/HeroPopover'
import { CalendarOutlined } from '~/icons'
import { OccupancyData, selectGetScheduledPractitioners, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getSurgeons } from '~/utils/dips'
import { day, format, getToday } from '~/utils/extendedDayjs'
import { isBefore } from '~/utils/extendedDayjs/helpers/isBefore'
import { hasMatchingPractitioner } from '~/utils/occupancy/occupancy'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

import { useOccupancyData } from '../../hooks/useOccupancyData'

type Props = {
    selectedMonth: Dayjs
    selectedDay?: Dayjs
    setSelectedDay: (day?: Dayjs) => void
    onMonthChange: (day: Dayjs) => void
    isLoading: boolean
    surgery: UnScheduledSurgery
    showAllPractitioners: boolean
}

export const AvailableDaysPicker = ({ selectedMonth, selectedDay, setSelectedDay, onMonthChange, isLoading, surgery, showAllPractitioners }: Props) => {
    const today = getToday()

    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const surgeryPractitioner = useMemo(() => (surgery ? getSurgeons(surgery.surgeryResources) : []), [surgery])

    const occupancyData = useOccupancyData(surgery, isLoading, selectedMonth)

    const availableDates = useCallback(
        (key: string) => {
            const datesObj: Record<string, OccupancyData[]> = {}
            const filteredDates = Object.entries(occupancyData).filter(([dateKey, listOfOccupancy]) => {
                const scheduledPractitioners = getScheduledPractitioners.byDateAndLocationsIds(
                    day(dateKey),
                    listOfOccupancy.map(occ => occ.location.id)
                )
                const hasFoundMatchingPractitioner = listOfOccupancy.find(occ => {
                    const practitionersForLocation = scheduledPractitioners?.[occ.location.id]
                    // dont show room if there are no practitioners
                    const noPractitioners = !practitionersForLocation || practitionersForLocation?.length === 0
                    if (noPractitioners) return false

                    // dont bother checking if we want to see all practitioners or if there are
                    // no practitioners asigned to the surgery
                    const nothingToMatch = showAllPractitioners || !surgeryPractitioner
                    if (nothingToMatch) return true

                    return hasMatchingPractitioner(surgeryPractitioner, practitionersForLocation)
                })
                return !!hasFoundMatchingPractitioner
            })
            filteredDates.forEach(([dateKey, listOfOccupancy]) => {
                datesObj[dateKey] = listOfOccupancy
            })
            return datesObj[key]
        },
        [occupancyData, getScheduledPractitioners, showAllPractitioners, surgeryPractitioner]
    )

    useEffect(() => {
        if (Object.keys(occupancyData).length === 0 || isLoading) return

        const firstAvailable = Object.entries(occupancyData).find(([dateKey]) => {
            if (isBefore(day(dateKey), today)) return false

            return !!availableDates(dateKey)
        })

        firstAvailable && firstAvailable?.length > 0 ? setSelectedDay(day(firstAvailable[0])) : setSelectedDay(undefined)
    }, [availableDates, isLoading, occupancyData, setSelectedDay, today])

    const isDateDisabled = (date: Date) => {
        const formattedDate = format(day(date), 'YYYY-MM-DD')
        const occupancyForSelectedDate = occupancyData[formattedDate]
        const hasNoSurgeryAvailability = !occupancyForSelectedDate || occupancyForSelectedDate.length === 0
        if (hasNoSurgeryAvailability) return true

        const datePractitioners = availableDates(formattedDate)
        // check needs to be done inside since we need to check if there are any practitioners

        return datePractitioners === undefined || datePractitioners.length === 0
    }

    return (
        <div className="relative flex w-full flex-col rounded border border-slate-200 px-4 py-2">
            <p className="text-slate-400">Dato: </p>
            {selectedDay?.month() === selectedMonth.month() ? (
                <p>{formatDateToNorskReadableIdDate(selectedDay).label}</p>
            ) : (
                <p className="text-slate-400">Ingen dato valgt</p>
            )}
            <HeroPopover
                onOpenChange={open => {
                    if (!open && selectedDay) onMonthChange(selectedDay?.startOf('month'))
                    else if (!open && !selectedDay) onMonthChange(today.startOf('month'))
                }}
                popoverContent={
                    <HeroDayPicker
                        required
                        disabled={[{ before: today.toDate() }, isDateDisabled]}
                        onMonthChange={date => onMonthChange(day(date))}
                        weekdaysOnly={true}
                        month={selectedMonth.toDate()}
                        // cant book in the past
                        startMonth={today.toDate()}
                        selected={selectedDay?.toDate()}
                        onSelect={date => setSelectedDay(day(date))}
                        mode="single"
                    />
                }
            >
                <button
                    className="absolute right-4 top-1 flex items-center gap-1 rounded bg-white px-2 py-1 text-xs shadow hover:bg-slate-50"
                    data-test="available-days-picker-trigger"
                >
                    <CalendarOutlined width={16} /> Åpne datovelger
                </button>
            </HeroPopover>
        </div>
    )
}
