import { Dayjs } from 'dayjs'

import {
    createPractitionerSchedule,
    createPractitionerScheduleStatus,
    deleteBatchPractitionerScheduleStatus,
    deletePractitionerScheduleStatus,
} from '~/store/di-entity.api'
import { Practitioner, selectGetPractitionerSchedules, selectGetPractitionerScheduleStatuses } from '~/store/selectors'
import { useStore } from '~/store/store'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs'

export const useOnCallScheduleActions = (date: Dayjs) => {
    async function deleteDateOnCall() {
        try {
            const practitionerSchedules = selectGetPractitionerSchedules(useStore.getState()).byDate(date)

            if (!practitionerSchedules) {
                return
            }

            const onCallPractitionerSchedules = practitionerSchedules.flatMap(practitionerSchedule => {
                return practitionerSchedule.statuses.filter(({ status_code }) => status_code === 'ON-CALL')
            })

            await deleteBatchPractitionerScheduleStatus(onCallPractitionerSchedules.map(({ id }) => id))
        } catch (e) {
            console.error(e)
        }
    }

    async function removeOnCallPractitioner(practitioner: Practitioner | null) {
        if (!practitioner) return

        try {
            const practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)

            if (!practitionerSchedule) {
                return
            }

            const practitionerScheduleStatuses = selectGetPractitionerScheduleStatuses(useStore.getState()).byPractitionerScheduleId(practitionerSchedule.id)
            const onCallStatus = practitionerScheduleStatuses.find(({ status_code }) => status_code === 'ON-CALL')

            if (!onCallStatus) {
                return
            }

            await deletePractitionerScheduleStatus(onCallStatus.id)
        } catch (e) {
            console.error(e)
        }
    }

    async function addOnCallPractitioner(practitioner: Practitioner | null) {
        if (!practitioner) return

        try {
            let practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)

            if (!practitionerSchedule) {
                await createPractitionerSchedule({
                    practitioner_id: practitioner.id,
                    start_time: date.format(),
                    end_time: date.add(1, 'day').format(),
                    tzid: HOSPITAL_TIMEZONE,
                })
                practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)
            }

            if (!practitionerSchedule) {
                throw new Error('Practitioner schedule not found')
            }

            await createPractitionerScheduleStatus({
                practitioner_schedule_id: Number(practitionerSchedule.id),
                status_code: 'ON-CALL',
            })
        } catch (e) {
            console.error(e)
        }
    }

    return { deleteDateOnCall, addOnCallPractitioner, removeOnCallPractitioner } as const
}
