import { useCallback, useMemo } from 'react'

import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { OccupancyData, Practitioner, selectGetUnScheduledSurgeries, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { getSurgeons, sortByOrderedDate } from '~/utils/dips'
import { hasMatchingPractitioner, isOccupancyBookable } from '~/utils/occupancy/occupancy'

type UnScheduledSuggestionsProps = { scheduledPractitioners: Practitioner[]; occupancyData: OccupancyData | null }

/**
 * Suggests unscheduled surgeries that can fit a surgery slot in an available block
 * @param scheduledPractitioners practitioners that are scheduled for the given date and location
 * @param occupancyData occupancy data for the given date and location
 * @returns list of unscheduled surgeries that can be scheduled for the given date and location
 */
export const useUnScheduledSuggestions = ({ scheduledPractitioners, occupancyData }: UnScheduledSuggestionsProps) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const unScheduledSurgeries = getUnScheduleSurgeries.byDepartmentKey(departmentKey)

    const { isError, isLoading } = useImportEntities(() => [importUnScheduledSurgeries()], [], { hasAlreadyData: Boolean(unScheduledSurgeries.length) })

    const filterByUnassignedOrScheduledPractitioners = useCallback(
        (surgery: UnScheduledSurgery) => {
            const surgeryPractitioners = getSurgeons(surgery.surgeryResources)
            return surgeryPractitioners.length === 0 || hasMatchingPractitioner(surgeryPractitioners, scheduledPractitioners)
        },
        [scheduledPractitioners]
    )

    const filterByMatchingOccupancy = useCallback(
        (surgery: UnScheduledSurgery) => {
            const surgeryTypeId = surgery.surgeryType?.id
            if (!surgeryTypeId || !occupancyData) return false

            return isOccupancyBookable({ occupancy: occupancyData, matchPatientGroup: { surgeryTypeId } })
        },
        [occupancyData]
    )

    const unScheduledSuggestions = useMemo(
        () => unScheduledSurgeries.filter(filterByUnassignedOrScheduledPractitioners).filter(filterByMatchingOccupancy).sort(sortByOrderedDate),
        [unScheduledSurgeries, filterByMatchingOccupancy, filterByUnassignedOrScheduledPractitioners]
    )

    const suggestionCount = unScheduledSuggestions.length

    return { unScheduledSuggestions, suggestionCount, isError, isLoading }
}
