import { useMemo, useState } from 'react'

import { HeroDialog, TextInput } from '~/components'
import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { sortByOrderedDate } from '~/utils/dips'

import { WaitingListItem } from '../../WaitingList/shared/columns'
import { SurgerySearchOption } from './SurgerySearchOption'

type Props = { isOpen: boolean; onClose: () => void; onClickSurgery: (surgery: WaitingListItem) => void }

export const SurgerySearchDialog = ({ isOpen, onClose, onClickSurgery }: Props) => {
    const [searchValue, setSearchValue] = useState<string>('')

    const unscheduledSurgery = useStore(selectGetUnScheduledSurgeries)
    const scheduledSugery = useStore(selectGetScheduledSurgeries)

    const searchOptions: WaitingListItem[] = useMemo(() => {
        if (searchValue.length < 3) return []
        return [...unscheduledSurgery.bySearchTerm(searchValue).sort(sortByOrderedDate), ...scheduledSugery.bySearchTerm(searchValue)]
    }, [scheduledSugery, searchValue, unscheduledSurgery])

    return (
        <HeroDialog
            isOpen={isOpen}
            onCloseDialog={() => {
                setSearchValue('')
                onClose()
            }}
            title="Finn pasient til denne tiden"
        >
            <div className="flex h-[640px] w-[540px] flex-col gap-8">
                <label className="flex flex-col gap-2">
                    Søk etter navn eller personnummer
                    <TextInput
                        type="search"
                        aria-label="Søk etter navn eller personnummer"
                        placeholder="Søk etter navn eller personnummer"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    />
                </label>
                <p className="font-bold">{searchOptions.length > 0 ? searchOptions.length : 'Ingen'} resultater</p>

                <div className="flex flex-col gap-3 overflow-y-auto">
                    {searchOptions.map((surgery, i) => (
                        <>
                            <SurgerySearchOption key={`${surgery.patient?.lastName} ${i}`} surgery={surgery} onClick={onClickSurgery} />
                            <hr />
                        </>
                    ))}
                </div>
            </div>
        </HeroDialog>
    )
}
