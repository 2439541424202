import * as Sentry from '@sentry/browser'
import { useEffect, useState } from 'react'

import env from '~/env'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { importFullScheduledSurgeries } from '~/store/dips-entity.api'
import { dispatchErrorToast } from '~/store/slices/toasterSlice'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { format, getToday } from '~/utils/extendedDayjs'

const NUM_PAGES = 6
const DAYS_PER_PAGE = 30

function useImportFutureFullScheduledSurgeries() {
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        async function mainFetch() {
            setIsFetching(true)

            try {
                await Sentry.startSpan({ name: 'importFutureFullScheduledSurgeries' }, async () => {
                    const today = getToday()

                    for (let i = 0; i < NUM_PAGES; i++) {
                        const startTime = format(today.add(i * DAYS_PER_PAGE, 'days'), 'YYYY-MM-DD')
                        const endTime = format(today.add((i + 1) * DAYS_PER_PAGE, 'days'), 'YYYY-MM-DD')
                        const treatmentLocationId = Number(env.VITE_DIPS_TREATMENT_LOCATION_ID)

                        await Sentry.startSpan({ name: 'GET /api/v1/surgery/scheduled' }, async () => {
                            await importFullScheduledSurgeries(startTime, endTime, treatmentLocationId)
                        })
                    }
                })
            } catch (error) {
                console.error(error)
                dispatchErrorToast('Det oppstod en feil. Vennligst prøv igjen.')
            } finally {
                setIsFetching(false)
            }
        }

        void mainFetch()
    }, [])

    return {
        isFetching,
    }
}

export function useImportFutureScheduledEntities() {
    const { isFetching } = useImportFutureFullScheduledSurgeries()
    const hasScheduledItems = useStore(state => Boolean(state.dips.entities.scheduledSurgeries.allIds.length))

    useImportEntities(() => [importSurgeryMetadata({})], [])

    return {
        isLoading: isFetching && !hasScheduledItems,
    }
}
