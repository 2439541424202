import './sentry'
import './augment.d.ts'
import './index.css'
import './utils/extendedDayjs/config'

import { MotionGlobalConfig } from 'framer-motion'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from '~/app/App'
import { Toaster, Tooltip } from '~/components'

import env from './env'

const rootElement = document.getElementById('root')

if (!rootElement) {
    throw new Error('Could not find root element')
}

if (env.VITE_NO_UNSTABLE_EFFECTS) {
    MotionGlobalConfig.skipAnimations = true
}

console.info(`Current version: ${env.VITE_GIT_TAG}${env.VITE_GIT_SHA ? `/${env.VITE_GIT_SHA}` : ''}`)

const component = (
    <div className="text-sm font-medium">
        <App />
        <Tooltip />
        <Toaster />
    </div>
)

createRoot(rootElement).render(env.VITE_STRICT_MODE ? <StrictMode>{component}</StrictMode> : component)
