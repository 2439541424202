import { FilterDropdown, LocationFilter, PractitionerFilter, RadioButtons, Show, StatusFilter } from '~/components'
import { selectSurgeonSchedulerFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

type Props = {
    isPractitionerView: boolean
}

export const Filters = ({ isPractitionerView }: Props) => {
    const { emptyFilters, removeSingleFilter } = useStore(state => state.appFilters.actions)
    const { toggleLocations } = useStore(state => state.surgeonScheduler.actions)

    const filters = useStore(selectSurgeonSchedulerFilters)
    const showLocations = useStore(state => state.surgeonScheduler.showLocations)

    return (
        <div className="flex gap-2">
            {isPractitionerView && (
                <RadioButtons
                    options={[
                        { label: 'Vis stuer', value: true },
                        { label: 'Skjul stuer', value: false },
                    ]}
                    data-test="locations-toggle"
                    value={showLocations}
                    onChange={toggleLocations}
                    size="md"
                />
            )}
            <FilterDropdown clearFilters={emptyFilters} onDisplayItemRemove={removeSingleFilter} displayItems={filters}>
                <Show
                    condition={isPractitionerView}
                    fallback={
                        <>
                            <PractitionerFilter />
                            <LocationFilter />
                        </>
                    }
                >
                    <PractitionerFilter />
                    <StatusFilter />
                </Show>
            </FilterDropdown>
        </div>
    )
}
