import { ReactNode } from 'react'

import { PagePaths, pages } from '~/app/routes'
import { ScheduledSurgery, UnScheduledSurgery } from '~/store/selectors'
import { DepartmentKey, getDayOvernight } from '~/store/slices/filterSlice'
import { formatPersonalIdWithFirstAndLastName, getAssistants, getSurgeons } from '~/utils/dips'
import { day, diff, format, formatDuration, getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'
import { getNorwegianText } from '~/utils/localization'

import { type TransformedWaitingListItem } from '../selectors/waitingListItems'
import { formatRemarks, formatStatuses, shouldShowASAColumn, waitingTimeTooltip } from './utils'

export type WaitingListItem = UnScheduledSurgery | ScheduledSurgery

export const isUnScheduledSurgery = (item: WaitingListItem): item is UnScheduledSurgery => {
    return 'bookingId' in item
}
export const getFormattedBookingId = (item: WaitingListItem) => {
    if (isUnScheduledSurgery(item) && item.bookingId) return item.bookingId
    if ('id' in item && item.id) return item.id
    return ''
}

type Column = {
    // for the header
    label: string | undefined | ReactNode
    // format data to text, primarily used in searching (case-insensitive) but sometimes in renders too
    format: (item: WaitingListItem) => string | string[]
    // for sorting
    getComparable: (item: WaitingListItem) => string | number | null
    // whether its column is displayed
    showColumn: boolean | ((departmentId: DepartmentKey, activeView: PagePaths['WAITING_LIST']) => boolean)
}

export const columns = {
    WaitingTime: {
        showColumn: true,
        label: (
            <div data-tooltip={waitingTimeTooltip} className="inline">
                Ventet
            </div>
        ),
        format: item => String(diff(getToday(), item.surgeryOrderDetails?.orderedDate, 'day')),
        getComparable: item => diff(getToday(), item.surgeryOrderDetails?.orderedDate, 'seconds'),
    },
    TentativeDate: {
        showColumn: (_, activeView) => activeView === pages.WAITING_LIST.views.all.path || activeView === pages.WAITING_LIST.views.unscheduled.path,
        label: 'Tentativ',
        format: item =>
            isNullish(item.contact?.checkInTime) || ['NotTentative', undefined].includes(item.contact?.tentative)
                ? ''
                : format(day(item.contact!.checkInTime), item.contact!.tentative === 'TentativeMonth' ? 'MMMM YYYY' : 'DD MMM YYYY'),
        getComparable: item =>
            isNullish(item.contact?.checkInTime)
                ? '-1'
                : String((['NotTentative', 'TentativeDate', 'TentativeMonth', undefined] as const).indexOf(item.contact!.tentative)) +
                  day(item.contact!.checkInTime).unix(),
    },
    OperationDate: {
        showColumn: (_, activeView) => activeView === pages.WAITING_LIST.views.all.path || activeView === pages.WAITING_LIST.views.scheduled.path,
        label: 'Operasjon',
        format: item => (item.contact?.tentative === 'NotTentative' && item.contact?.checkInTime ? format(day(item.contact!.checkInTime), 'DD.MM.YYYY') : ''),
        getComparable: item =>
            isNullish(item.contact?.checkInTime)
                ? Infinity
                : String((['NotTentative', 'TentativeDate', 'TentativeMonth', undefined] as const).indexOf(item.contact!.tentative)) +
                  day(item.contact!.checkInTime).unix(),
    },
    Patient: {
        showColumn: true,
        label: 'Pasient',
        format: item => `${formatPersonalIdWithFirstAndLastName(item.patient)}`,
        getComparable: item => item.patient?.lastName ?? null,
    },
    ASA: {
        showColumn: shouldShowASAColumn,
        label: 'ASA',
        format: item => item.surgeryOrderDetails?.asa ?? '',
        getComparable: item => item.surgeryOrderDetails?.asa ?? null,
    },
    Remarks: {
        showColumn: true,
        label: 'Merknad',
        format: item => formatRemarks(item),
        getComparable: item => formatRemarks(item).length,
    },
    Comment: {
        showColumn: true,
        label: (
            <div data-test="comment-header" className="inline">
                Oppmøtekommentar
            </div>
        ),
        format: item => item.surgeryMetadata?.comment ?? item.contact?.coordinationComment ?? '',
        getComparable: item => item.surgeryMetadata?.comment ?? item.contact?.coordinationComment ?? null,
    },
    DIPSComment: {
        showColumn: false,
        label: 'Oppmøtekommentar fra DIPS',
        format: item => item.contact?.coordinationComment ?? '',
        getComparable: item => item.contact?.coordinationComment ?? null,
    },
    KnifeTime: {
        showColumn: true,
        label: 'Knivtid',
        format: item => (item?.plannedProcedureDuration && formatDuration(item.plannedProcedureDuration)) || '',
        getComparable: item => item?.plannedProcedureDuration ?? null,
    },
    SurgeryType: {
        showColumn: true,
        label: 'Operasjonstype',
        format: item => item.surgeryType?.name ?? '',
        getComparable: item => item.surgeryType?.name ?? null,
    },
    Surgeons: {
        showColumn: true,
        label: 'Kirurger',
        format: item =>
            getSurgeons(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort(),
        getComparable: item =>
            getSurgeons(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort()
                .join(' '),
    },
    FirstAssistants: {
        showColumn: false,
        label: 'Assisterende kirurg',
        format: item =>
            getAssistants(item.surgeryResources)
                .map(({ short_name }) => short_name)
                .filter(Boolean)
                .sort(),
        getComparable: item =>
            getAssistants(item.surgeryResources)
                .map(({ short_name }) => short_name ?? '')
                .sort()
                .join(' '),
    },
    Statuses: {
        showColumn: true,
        label: 'Status',
        format: item => formatStatuses(item),
        getComparable: item => {
            const itemStatuses = formatStatuses(item)
            return itemStatuses.includes('ny') ? 2 : itemStatuses.length // sort by new first
        },
    },
    BookingId: {
        showColumn: false,
        label: 'Id',
        format: getFormattedBookingId,
        getComparable: getFormattedBookingId,
    },
    BMI: {
        showColumn: false,
        label: 'BMI',
        format: item => item.surgeryOrderDetails?.bmi ?? '',
        getComparable: item => item.surgeryOrderDetails?.bmi ?? '',
    },
    ContactReason: {
        showColumn: false,
        label: 'Kontaktårsak',
        format: item => item.contact?.note ?? '',
        getComparable: item => item.contact?.note ?? null,
    },
    Day: {
        showColumn: false,
        label: 'Dag/Døgn',
        format: item => getDayOvernight(item.contact?.levelOfCareNpr?.nprCodeName)?.label ?? '',
        getComparable: item => item.contact?.levelOfCareNpr?.nprCodeName ?? null,
    },
    Age: {
        showColumn: false,
        label: 'Alder',
        format: item => `${(item.patient?.birthDate && String(diff(getToday(), item.patient.birthDate, 'year'))) || '?'} ${getNorwegianText('year')}`,
        getComparable: item => item.patient?.birthDate ?? null,
    },
    BirthDate: {
        // makes the patient searchable by birth date
        showColumn: false,
        label: 'fødselsdato',
        format: item => (item.patient?.birthDate && `Fødselsdato:\n${format(day(item.patient.birthDate), 'DD.MM.YYYY')}`) || '',
        getComparable: item => item.patient?.birthDate ?? null,
    },
    BookedStatus: {
        showColumn: false,
        label: undefined,
        format: item => (isUnScheduledSurgery(item) ? (item.contact?.tentative === 'NotTentative' ? 'NotTentative' : 'Waiting') : 'Scheduled'),
        getComparable: item => (item.contact?.tentative === 'NotTentative' ? 1 : 0),
    },
} satisfies Record<string, Column>

export type WaitingListColumn = keyof typeof columns
export type FormattedWaitingListItem = { [K in keyof TransformedWaitingListItem]: TransformedWaitingListItem[K]['formatted'] }
