import { Span, startSpan } from '@sentry/react'
import { useEffect, useState } from 'react'

import { DipsSchemas, getClient } from '~/clients/dips-client'

export type SagaStatus = 'Failed' | 'Ongoing' | 'Completed'

type StatusType = { status: SagaStatus; message: string }

type SagaStatusResponse = DipsSchemas['SagaStatusDto']

// These are aligned with OpenTelemetry span status codes and not exported by the Sentry SDK.
const SPAN_STATUS_OK = 1
const SPAN_STATUS_ERROR = 2

export const useGetSurgeryBookingStatus = ({ sagaId }: { sagaId?: string }) => {
    // find proper message for ongoing
    const [status, setStatus] = useState<StatusType>({ status: 'Ongoing', message: 'Ongoing' })
    const [surgerySagaId, setSurgerySagaIdToPoll] = useState<string | undefined>(sagaId)
    const [error, setError] = useState<string | null>(null)

    const handlePollingResponse = ({ status, errorMessage }: SagaStatusResponse, interval?: NodeJS.Timeout, span?: Span) => {
        if (errorMessage) {
            setError(errorMessage)
            setStatus({ status: 'Failed', message: errorMessage })
            span?.setStatus({ code: SPAN_STATUS_ERROR })
            interval && clearInterval(interval)
        }
        if (status !== 'Ongoing') {
            setStatus({ status: status as SagaStatus, message: status })
            span?.setStatus({ code: status === 'Failed' ? SPAN_STATUS_ERROR : SPAN_STATUS_OK })
            interval && clearInterval(interval)
        }
    }

    useEffect(() => {
        // if the status is COMPLETED or FAILED, stop polling
        if (!surgerySagaId || status?.status === 'Completed' || status?.status === 'Failed') return
        let interval: NodeJS.Timeout | undefined
        // this sets ups the parent span to track how long it takes to get a completed booking
        startSpan(
            {
                name: 'Booking Status Poll',
            },
            parentSpan => {
                interval = setInterval(async () => {
                    const finishFailedSpan = (message = 'internal_error') => {
                        parentSpan?.setStatus({ code: SPAN_STATUS_ERROR, message })
                        interval && clearInterval(interval)
                    }
                    try {
                        const res = await getClient().GET('/api/v1/saga/{sagaId}/status', {
                            params: {
                                path: { sagaId: surgerySagaId },
                            },
                        })
                        if (res.response.status === 200 && res.data?.status) {
                            handlePollingResponse(res.data, interval, parentSpan)
                        } else {
                            setError('Failed to get status')
                            finishFailedSpan('internal_error')
                        }
                    } catch (e) {
                        setError((e as Error).message)
                        finishFailedSpan('unknown_error')
                    }
                }, 2000)
            }
        )

        return () => {
            interval && clearInterval(interval)
        }
    }, [surgerySagaId, status])

    return { status, setSurgerySagaIdToPoll, error }
}
