import clsx from 'clsx'

import { CheckOutlined } from '~/icons'
import { selectEntities } from '~/store/selectors'
import { DepartmentKey } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

export const DepartmentFilter = () => {
    const { departmentKey } = useStore(state => state.appFilters)
    const { setDepartment } = useStore(state => state.appFilters.actions)
    const { departments } = useStore(selectEntities)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const departmentOptions = [
        { label: 'Alle', value: 'all' as const, external_id: null },
        ...departments.filter(Boolean)?.map(({ name, external_id, id }) => ({ label: name, value: id, external_id: external_id ?? null })),
    ]

    const handleOnClick = (value: DepartmentKey, external_id: number | null) => {
        setDepartment(value, external_id)
        setSelectedPage(1)
    }

    return (
        <>
            <div className="px-2 py-1 font-normal">Avdelinger</div>
            <div className="mt-2 flex flex-col gap-2">
                {departmentOptions.map(({ label, value, external_id }) => (
                    <div
                        data-test={`${label}-radio-button`}
                        key={String(value)}
                        className={clsx(
                            'flex cursor-pointer items-center justify-between rounded px-2 py-2.5 font-normal hover:bg-diBlue-100',
                            value === departmentKey ? 'bg-diBlue-100' : 'bg-neutral-100'
                        )}
                        onClick={() => handleOnClick(value, external_id)}
                    >
                        {label}
                        {value === departmentKey && <CheckOutlined className="h-5 w-5" />}
                    </div>
                ))}
            </div>
        </>
    )
}
