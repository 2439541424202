import dayjs from 'dayjs'

import { selectGetLocations, selectGetScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day } from '~/utils/extendedDayjs'
import { isBefore } from '~/utils/extendedDayjs/helpers/isBefore'

import { EmptyItem } from './EmptyItem'
import { SurgeryCard } from './SurgeryCard'

export const BookingDrawerBookings = () => {
    const { location_id, unixDate } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)
    const getLocations = useStore(selectGetLocations)
    const location = location_id !== undefined ? getLocations.byId(location_id) : null
    const date = dayjs.unix(unixDate ?? 0)

    if (!unixDate) return null
    if (!location) return null

    const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code).toSorted((surgery1, surgery2) => {
        const timeA = surgery1.contact?.checkInTime
        const timeB = surgery2.contact?.checkInTime

        // Sort nullish last
        if (!timeA && !timeB) return 0
        if (!timeA) return 1
        if (!timeB) return -1

        return isBefore(day(timeA), day(timeB)) ? -1 : 1
    })

    return (
        <div>
            <div className="flex items-center gap-4 pb-1">
                <p className="text-base font-semibold">Bestilte Operasjoner</p>
                {bookedSurgeries.length > 0 && <p className="text-sm text-gray-500">{bookedSurgeries.length} bestilt</p>}
            </div>
            <div className="flex flex-col gap-2">
                {bookedSurgeries.length > 0 ? (
                    bookedSurgeries.map((surgery, index) => <SurgeryCard surgery={surgery} key={index} />)
                ) : (
                    <EmptyItem>
                        <p>Det er ingen pasienter på programmet.</p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-loaded" className="hidden" />
            </div>
        </div>
    )
}
