import * as Tooltip from '@radix-ui/react-tooltip'
import { TooltipContentProps } from '@radix-ui/react-tooltip'
import { motion } from 'framer-motion'

type Props = {
    children: React.ReactNode
    tooltipContent: React.ReactNode
    onOpenChange?: (open: boolean) => void
} & TooltipContentProps

export const HeroTooltip = ({ children, tooltipContent, ...contentProps }: Props) => {
    return (
        <Tooltip.Provider>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
                <Tooltip.Portal>
                    {tooltipContent && (
                        <Tooltip.Content
                            asChild
                            className="z-20 w-fit rounded border bg-slate-700 px-2 py-1 text-xs text-white shadow-lg"
                            sideOffset={5}
                            {...contentProps}
                        >
                            <motion.div initial={{ opacity: 0, y: 25 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }}>
                                <Tooltip.Arrow className="translate-y-[-1px] fill-slate-700" />
                                {tooltipContent}
                            </motion.div>
                        </Tooltip.Content>
                    )}
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    )
}
