import { useMemo } from 'react'

import { Button, CollapsibleCard, PractitionerTags, Show, Tag } from '~/components'
import env from '~/env'
import { CalendarTodayOutlined, EventAvailableOutlined } from '~/icons'
import { UnScheduledSurgery } from '~/store/selectors'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

import { isUnScheduledSurgery, WaitingListItem } from '../../WaitingList/shared/columns'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

type Props = {
    surgery: WaitingListItem
    hideAction?: boolean
    onClick?: (selectedSurgery: UnScheduledSurgery) => void
}

export const SurgeryCard = ({ surgery, hideAction, onClick }: Props) => {
    const isUnscheduled = isUnScheduledSurgery(surgery)
    const { dayOvernight, asa, patientName, checkInTime, plannedStartTime, knifeTime, practitioners } = useMemo(() => getFormattedSurgery(surgery), [surgery])

    const CardContent = () => (
        <div className="pb-2 pt-4">
            {surgery.contact?.coordinationComment && (
                <div className="flex flex-col gap-1 pb-2">
                    <p className="text-sm font-semibold">Oppmøtekommentar:</p>
                    <p className="text-sm"> {surgery.contact?.coordinationComment}</p>
                </div>
            )}
            <PractitionerTags practitioners={practitioners} style="blue" />
            {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON && isUnscheduled && !hideAction && (
                <Button color="secondary" onClick={() => onClick?.(surgery)} className="mt-4" data-test="order-button">
                    Bestill operasjon
                </Button>
            )}
        </div>
    )

    return (
        <>
            <CollapsibleCard
                variant={isUnscheduled ? 'light' : 'default'}
                title={surgery.surgeryType?.name ?? ''}
                icon={!isUnscheduled ? <EventAvailableOutlined width={20} height={20} /> : <CalendarTodayOutlined width={20} height={20} />}
                secondaryIcon={
                    dayOvernight && (
                        <div className="flex items-center gap-1">
                            {dayOvernight.icon}
                            <p>{dayOvernight.label}</p>
                        </div>
                    )
                }
                collapsedContent={
                    <div className="flex flex-col gap-4" data-test={isUnscheduled ? 'BookingDrawerPatients' : 'ScheduledSurgery'}>
                        <div className="flex items-center justify-between">
                            <p className="">{patientName}</p>
                            <Show condition={!!asa}>
                                <Tag color="ASA" textSize="sm">
                                    {asa}
                                </Tag>
                            </Show>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-sm">Oppmøte: {checkInTime}</p>
                            <p className="text-sm">Stue inn: {plannedStartTime}</p>
                            <p className="text-sm">Knivtid: {knifeTime}</p>
                        </div>
                    </div>
                }
            >
                <CardContent />
            </CollapsibleCard>
        </>
    )
}
