import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'

import { baseClient } from '~/clients/api-client'
import { UserInfoResponse } from '~/clients/derived-api-types'
import { isDipsUrlConfigured } from '~/clients/dips-client'
import env from '~/env'
import { clearPartialStorage, useStore } from '~/store/store'
import { analytics, anonymizedUser } from '~/utils/analytics'
import { scheduleRefreshingAccessToken } from '~/utils/token'

import AuthenticatedAppContents from './AuthenticatedAppContents'
import LoadingScreen from './LoadingScreen'
import AuthPage from './pages/Auth/Auth.page'
import PKCEAuthenticationFlow from './PKCEAuthenticationFlow'

function useRemoveDILoaderElementEffect(options: { shouldRemove: boolean }): void {
    useEffect(() => {
        if (options.shouldRemove) {
            document.getElementById('di-loader')?.remove()
        }
    }, [options.shouldRemove])
}

function useSetAuthenticationData(data: UserInfoResponse | undefined): void {
    const { setAuth } = useStore(state => state.user.actions)
    useEffect(() => {
        if (data === undefined) return

        setAuth({
            name: data?.userinfo.name ?? null,
            accessToken: data?.access_token ?? null,
            issuer: data?.issuer ?? null,
        })
    }, [data, setAuth])
}

function useScheduleRefreshingAccessToken(data: UserInfoResponse | undefined) {
    const refreshTokenFeatureFlag = useStore(state => state.featureFlags.refreshToken)
    const { setAuth } = useStore(state => state.user.actions)
    const [isRefreshingAccessToken, setIsRefreshingAccessToken] = useState(false)

    useEffect(() => {
        if (!refreshTokenFeatureFlag || data === undefined || data.access_token === undefined) {
            return
        }

        // schedule a periodic refreshing of the access token
        if (!isRefreshingAccessToken) {
            setIsRefreshingAccessToken(true)
            scheduleRefreshingAccessToken({
                currentAccessToken: data,
                callback: newAccessToken => {
                    setAuth({ accessToken: newAccessToken?.access_token ?? null })
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTokenFeatureFlag, isRefreshingAccessToken, data])
}

function useFetchUserInfo() {
    const [data, setData] = useState<UserInfoResponse>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchUserInfo() {
            setIsLoading(true)

            const response = await baseClient.GET('/oauth/userinfo')
            setData(response.data)

            setIsLoading(false)
        }

        void fetchUserInfo()
    }, [])

    return { data, isLoading }
}

if (!isDipsUrlConfigured()) {
    console.warn('DIPS URL is not configured for this environment.')
}

const App = () => {
    const { data, isLoading } = useFetchUserInfo()
    const dipsApiAccessToken = useStore(state => state.user.dipsApiAccessToken)
    const isDeepinsightUser = data?.issuer === 'google'

    useRemoveDILoaderElementEffect({ shouldRemove: true })
    useSetAuthenticationData(data)
    useScheduleRefreshingAccessToken(data)

    useEffect(() => {
        async function identifyUser() {
            if (dipsApiAccessToken) {
                const idToken = jwtDecode(dipsApiAccessToken)
                const user = await analytics?.user()
                const anonymousUserId = anonymizedUser(idToken.sub ?? user?.anonymousId() ?? 'anonymous')
                void analytics?.identify(anonymousUserId, { tenant: env.VITE_SEGMENT_TENANT_NAME, environment: env.VITE_SENTRY_ENVIRONMENT })
            }
        }
        void identifyUser()
    }, [dipsApiAccessToken])

    if (isLoading) {
        return <LoadingScreen message="◦ ◦" />
    }

    if (data === undefined || data.userinfo === undefined || data.access_token === undefined) {
        clearPartialStorage()
        return <AuthPage />
    }

    if (!dipsApiAccessToken && (!isDeepinsightUser || env.VITE_PKCE_FOR_DEVS)) {
        if (env.VITE_PKCE_AUTHORIZE_URL) {
            return <PKCEAuthenticationFlow />
        } else {
            return <LoadingScreen message="◦ ◦ ◦" /> // prevents race condition where state.app.dipsApiAccessToken is still null (in non-PKCE flow). If we don't wait, a component below can call DIPS API → responds with 401 → we get logged out
        }
    }

    return <AuthenticatedAppContents />
}

export default App
