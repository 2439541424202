import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Show } from '~/components'
import { Location, OccupancyData } from '~/store/selectors'
import { MinimalPractitioner } from '~/utils/dips'
import { getOnlyDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

import { BlockIcons } from '../../shared/BlockIcons'
import { PractitionerTags } from '../../shared/PractitionerTags'
import { AvailabilitiesList } from './AvailabilitiesList'
import { BookingsList } from './BookingsList'

type Style = 'available' | 'booked'

export const styles: Record<Style, string> = {
    available: 'bg-white border-2 border-diBlue-500',
    booked: 'bg-indigo-100 bg-opacity-70',
}

type Props = {
    date: Dayjs
    location: Location
    occupancyData: OccupancyData
    practitioners: MinimalPractitioner[]
    style: Style
    showIcons?: boolean
    className?: string
    onClick?: () => void
    dataTest?: string
}

export const BlockCard = ({ date, location, occupancyData, practitioners, className, style, onClick, showIcons, dataTest }: Props) => (
    <div
        data-test={dataTest}
        className={clsx(className, 'flex h-full flex-col justify-between gap-2 rounded p-2', styles[style], {
            'cursor-pointer': isNotNullish(onClick),
        })}
        onClick={onClick}
    >
        <div className="flex flex-col gap-4">
            <Show
                condition={style === 'available'}
                fallback={<BookingsList occupancyData={occupancyData} data-test={`${location.room_code}-${getOnlyDate(date)}`} />}
            >
                <AvailabilitiesList occupancyData={occupancyData} data-test={`${location.room_code}-${getOnlyDate(date)}`} />
            </Show>
            <Show condition={practitioners.length > 0}>
                <PractitionerTags practitioners={practitioners} />
            </Show>
        </div>

        {showIcons && <BlockIcons date={date} location={location} />}
    </div>
)
