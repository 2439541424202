import { paths } from '~/clients/generated-dips-types'

import { BaseDownloadButton } from './BaseDownloadButton'

type ButtonProps = {
    label: string
}

type CodeListButtonProps = ButtonProps & {
    codelistid: number
}

const basePath = '/api/v1/organization'

export const DownloadCodeListButton = ({ label, codelistid }: CodeListButtonProps) => {
    const path = `${basePath}/code-list/{codelistid}`
    type Params = paths[typeof path]['get']['parameters']
    const params = { path: { codelistid } } satisfies Params

    return BaseDownloadButton({ label, path, params })
}

export const DownloadSwaggerButton = ({ label }: ButtonProps) => {
    const path = '/swagger/v1/swagger.json'

    return BaseDownloadButton({ label, path })
}

export const DownloadLocalizationsButton = ({ label }: ButtonProps) => {
    const path = `${basePath}/localizations`
    type Params = paths[typeof path]['get']['parameters']
    const params = { query: { includeNotAccessTo: true } } satisfies Params

    return BaseDownloadButton({ label, path, params })
}

export const DownloadDepartmentsButton = ({ label }: ButtonProps) => {
    const path = `${basePath}/departments`
    type Params = paths[typeof path]['get']['parameters']
    const params = { query: { includeNotAccessTo: true, includeReplaced: true } } satisfies Params

    return BaseDownloadButton({ label, path, params })
}

export const DownloadSectionsButton = ({ label }: ButtonProps) => {
    const path = `${basePath}/sections`
    type Params = paths[typeof path]['get']['parameters']
    const params = { query: { includeNotAccessTo: true, includeReplaced: true } } satisfies Params

    return BaseDownloadButton({ label, path, params })
}

export const DownloadWardsButton = ({ label }: ButtonProps) => {
    const path = `${basePath}/wards`
    type Params = paths[typeof path]['get']['parameters']
    const params = { query: { includeNotAccessTo: true, includeReplaced: true } } satisfies Params

    return BaseDownloadButton({ label, path, params })
}
