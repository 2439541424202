import * as Popover from '@radix-ui/react-popover'
import { PopoverContentProps } from '@radix-ui/react-popover'
import { motion } from 'framer-motion'

type Props = { children: React.ReactNode; popoverContent: React.ReactNode; onOpenChange?: (open: boolean) => void } & PopoverContentProps

export const HeroPopover = ({ children, popoverContent, onOpenChange, ...contentProps }: Props) => {
    return (
        <Popover.Root
            modal={true}
            onOpenChange={open => {
                onOpenChange?.(open)
            }}
        >
            <Popover.Trigger asChild>{children}</Popover.Trigger>
            <Popover.Portal>
                <Popover.Content asChild className="w-96 rounded border border-slate-200 bg-white p-4 shadow-xl" sideOffset={5} {...contentProps}>
                    <motion.div initial={{ opacity: 0, y: 25 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }}>
                        <Popover.Arrow className="fill-white" />
                        {popoverContent}
                    </motion.div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}
