export type Id = string | number
export type Cell = { rowId: string; colId: string }

export function toCellId({ colIndex, rowIndex }: { rowIndex: number; colIndex: number }) {
    return `${rowIndex}-${colIndex}`
}

export function fromCellId(cellId: string) {
    const [rowIndex, colIndex] = cellId.split('-').map(Number)
    return { rowIndex, colIndex }
}

export const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'] as const
export type ArrowKey = (typeof arrowKeys)[number]

export function isArrowKey(key: string): key is ArrowKey {
    return arrowKeys.includes(key)
}

export function toCells(selectedCells: Set<string>): Cell[] {
    return Array.from(selectedCells)
        .map(cell => {
            const cellElement = document.getElementById(cell)
            if (!cellElement) return null

            return {
                rowId: String(cellElement.dataset.rowId),
                colId: String(cellElement.dataset.colId),
            }
        })
        .filter(Boolean)
}
