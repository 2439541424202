import * as Collapsible from '@radix-ui/react-collapsible'
import clsx from 'clsx'
import { ReactNode, useState } from 'react'

import { DownOutlined } from '~/icons'

const variantStyles = {
    default: 'bg-indigo-50',
    heavy: 'bg-gray-200',
    light: 'bg-gray-100',
    white: 'bg-white',
}

type Props = {
    icon?: ReactNode
    title: string | ReactNode
    children?: ReactNode
    defaultOpen?: boolean
    secondaryIcon?: ReactNode
    collapsedContent?: ReactNode
    variant?: keyof typeof variantStyles
}

export const CollapsibleCard = ({ icon, title, children, defaultOpen = true, secondaryIcon, collapsedContent, variant = 'default' }: Props) => {
    const [isOpen, setIsOpen] = useState(defaultOpen)
    const headerStyles = clsx(`flex items-center justify-between gap-1 px-2 py-2 ${variantStyles[variant]} border-b`)

    return (
        <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
            <div className="w-full overflow-hidden rounded border pb-1 shadow-md">
                <Collapsible.Trigger asChild className="w-full">
                    <>
                        <div className={headerStyles}>
                            <div className="flex items-center gap-1">
                                {icon}
                                {typeof title === 'string' ? <p className="max-w-[300px] text-pretty text-left font-semibold">{title}</p> : title}
                            </div>
                            <div className="flex items-center gap-2">
                                {secondaryIcon}
                                <button onClick={() => setIsOpen(prev => !prev)} className={`${isOpen ? 'rotate-180' : ''}`}>
                                    <DownOutlined />
                                </button>
                            </div>
                        </div>
                        {collapsedContent && <div className="bg-white px-3 pt-4">{collapsedContent}</div>}
                    </>
                </Collapsible.Trigger>

                {isOpen && (
                    <Collapsible.Content forceMount>
                        <div className="overflow-hidden px-3">{children}</div>
                    </Collapsible.Content>
                )}
            </div>
        </Collapsible.Root>
    )
}
