import { useRef } from 'react'

import { Show } from '~/components'
import { useStore } from '~/store/store'
import { getLastNameAndBirthYear } from '~/utils/dips'
import { isEllipsisActive } from '~/utils/utils'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'
import { columns, FormattedWaitingListItem } from '../shared/columns'
import { Remarks } from '../shared/Remarks'
import { Statuses } from '../shared/Statuses'
import { WaitingListTags } from '../shared/WaitingListTags'
import { Cell } from './Cell'

type Props = {
    item: FormattedWaitingListItem
    className?: string
    'data-test'?: string
}

export const TableRowCells = ({ item }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const isAll = activeView === '/waiting-list/all'
    const isAllOrUnscheduled = isAll || activeView === '/waiting-list/unscheduled'
    const isAllOrScheduled = isAll || activeView === '/waiting-list/scheduled'
    const commentRef = useRef(null)
    const surgeryTypeRef = useRef(null)
    const surgery = useGetWaitingListSurgery(item)
    const patient = getLastNameAndBirthYear(surgery?.patient)

    return (
        <>
            <Cell scope="row">{item.WaitingTime}</Cell>
            <Show condition={isAllOrUnscheduled}>
                <Cell>{item.TentativeDate}</Cell>
            </Show>
            <Show condition={isAllOrScheduled}>
                <Cell>{item.OperationDate}</Cell>
            </Show>
            <Cell>
                <div data-tooltip={item.BirthDate}>{patient}</div>
            </Cell>
            <Show condition={columns.ASA.showColumn(departmentKey)}>
                <Cell>
                    <WaitingListTags tags={item.ASA} tagColor="ASA" />
                </Cell>
            </Show>
            <Cell>
                <Remarks remarks={item.Remarks} />
            </Cell>
            <Cell maxWidth={true} data-tooltip={commentRef?.current && isEllipsisActive(commentRef.current) ? item.Comment : ''}>
                <div ref={commentRef} className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.Comment}
                </div>
            </Cell>
            <Cell>{item.KnifeTime}</Cell>
            <Cell maxWidth={true} data-tooltip={surgeryTypeRef?.current && isEllipsisActive(surgeryTypeRef.current) ? item.SurgeryType : ''}>
                <div ref={surgeryTypeRef} className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.SurgeryType}
                </div>
            </Cell>
            <Cell>
                <WaitingListTags tags={item.Surgeons} />
            </Cell>
            <Cell>
                <Statuses statuses={item.Statuses} isScheduledItem={item.BookedStatus === 'Scheduled'} />
            </Cell>
        </>
    )
}
