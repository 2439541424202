import { Show, Tag } from '~/components'
import { AccountCircleOutlined, KnifeOutlined } from '~/icons'
import { getDayOvernight } from '~/store/slices/filterSlice'

import { FormattedWaitingListItem, WaitingListItem } from '../../shared/columns'

type SurgeonTagProps = {
    label: string
}

const SurgeonTag = ({ label }: SurgeonTagProps) => (
    <Tag color="surgeryInfo">
        <AccountCircleOutlined className="h-4 w-4" />
        {label}
    </Tag>
)

type SurgeryInfoProps = {
    item: FormattedWaitingListItem
    surgery: WaitingListItem
}

export const SurgeryInfo = ({ item, surgery }: SurgeryInfoProps) => {
    const dayOvernight = getDayOvernight(surgery.contact?.levelOfCareNpr?.nprCodeName)

    return (
        <div className="flex flex-col gap-2 rounded-s bg-gray-50 px-2 py-4">
            <p className="font-semibold">{item.SurgeryType}</p>
            <div className="flex flex-row gap-1">
                <Show condition={item.Surgeons.length > 0} fallback={<SurgeonTag label="Alle" />}>
                    {item.Surgeons.map(surgeon => (
                        <SurgeonTag key={surgeon} label={surgeon} />
                    ))}
                </Show>
                <Show condition={Boolean(item.KnifeTime)}>
                    <Tag color="surgeryInfo">
                        <KnifeOutlined className="h-4 w-4" />
                        {item.KnifeTime}
                    </Tag>
                </Show>
                <Show condition={Boolean(dayOvernight)}>
                    <Tag color="surgeryInfo">
                        {dayOvernight?.icon} {dayOvernight?.label}
                    </Tag>
                </Show>
                <Show condition={Boolean(item.BMI)}>
                    <Tag color="surgeryInfo">{`BMI ${item.BMI}`}</Tag>
                </Show>
                <Show condition={Boolean(item.ASA)}>
                    <Tag color="surgeryInfo">{item.ASA}</Tag>
                </Show>
            </div>
        </div>
    )
}
