import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar } from '~/components'
import PageLayout from '~/components/PageLayout'
import { importBlockSchedules, importPractitionerSchedules } from '~/store/di-entity.api'
import { selectTimeArgs } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { Filter } from './Header/Filter'

export default function PlanningPeriodsPage() {
    const timeArgs = useStore(selectTimeArgs)

    // const isCalendarView = useStore(state => state.app.activeViews.PLANNING_PERIODS) === '/periods/kapasitet'

    const {} = useImportEntities(
        () => [importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true }), importBlockSchedules({ ...timeArgs })],
        [timeArgs]
    )

    return (
        <PageLayout
            content={<></>}
            header={
                <Bar
                    left={<ViewSwitcher pageKey="PLANNING_PERIODS" />}
                    right={
                        <div className="flex gap-2">
                            <Filter />
                        </div>
                    }
                />
            }
        />
    )
}
