import { offset, useFloating, useTransitionStyles } from '@floating-ui/react'
import { ReactNode, useState } from 'react'

import { Show } from '~/components'
import { useAutoHide } from '~/hooks/useAutoHide'

type Props = {
    copyText: string
    children: ReactNode
}

export const CopyToClipboard = ({ copyText, children }: Props) => {
    const [copyMessage, setCopyMessage] = useState('')
    const [showCopyMessage, setShowCopyMessage] = useAutoHide()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(copyText)
            setCopyMessage('Kopiert!')
        } catch (err) {
            setCopyMessage('Klarte ikke å kopiere!')
        } finally {
            setShowCopyMessage()
        }
    }

    const { refs, floatingStyles, context } = useFloating({
        placement: 'top',
        open: showCopyMessage,
        middleware: [offset(5)],
    })

    const { isMounted, styles } = useTransitionStyles(context)

    return (
        <>
            <div ref={refs.setReference} onClick={copyToClipboard}>
                {children}
            </div>

            <Show condition={isMounted}>
                <div ref={refs.setFloating} className="z-50" style={{ ...floatingStyles, ...styles }}>
                    <span className="rounded bg-black px-3 py-2 text-xs text-white shadow">{copyMessage}</span>
                </div>
            </Show>
        </>
    )
}
