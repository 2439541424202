import { DayPicker, DayPickerProps, DayProps, getDefaultClassNames, WeekdayProps } from 'react-day-picker'
// @ts-expect-error - Missing types
import { nb } from 'react-day-picker/locale'

import { day } from '~/utils/extendedDayjs'

type DiProps = DayPickerProps & {
    weekdaysOnly?: boolean
}

const selectedStyles = 'relative bg-blue-100 text-black after:border-blue-500 after:border after:block after:top-0 after:absolute after:w-full after:h-full '
const todayStyles =
    'relative text-black after:border-blue-500 after:border after:block after:top-0 after:absolute after:w-full after:h-full after:pointer-events-none'

export const HeroDayPicker = ({ weekdaysOnly, ...rest }: DiProps) => {
    const defaultClassNames = getDefaultClassNames()

    // Hides the weekends
    const dayRenerder = (props: DayProps) => {
        const { day: renderDay } = props

        if (weekdaysOnly && (day(renderDay.date).day() === 6 || day(renderDay.date).day() === 0)) {
            return <></>
        }
        // @ts-expect-error - Missing types
        return <td {...props} data-test={props['data-disabled'] ? 'booking-day-disabled' : 'booking-day-available'} />
    }

    // hide the column headers for the weekends
    const weekdayRenderer = (props: WeekdayProps) => {
        if (weekdaysOnly && (props.children === 'L' || props.children === 'S')) {
            return <></>
        }

        return <th {...props} />
    }

    return (
        <DayPicker
            locale={nb}
            formatters={{ formatWeekdayName: date => day(date).format('dd').at(0)?.toUpperCase() ?? '' }}
            mode="single"
            fixedWeeks
            showWeekNumber
            // timeZone="Europe/Oslo"
            classNames={{
                today: todayStyles, // Add a border to today's date
                selected: selectedStyles, // Highlight the selected day
                root: `${defaultClassNames.root} relative`, // Add a shadow to the root element
                chevron: `${defaultClassNames.chevron} fill-gray-500`, // Change the color of the chevron
                day_button: 'w-full h-[50px] align-center hover:bg-gray-100 disabled:opacity-15',
                nav: 'absolute top-0 right-0',
                day: 'border',
                weeks: 'rounded',
                month_grid: 'w-full',
                month: 'capitalize',
                week_number: 'text-sm px-2 bg-gray-50 text-gray-500 w-2',
                caption_label: 'text-md text-gray-500',
                month_caption: 'pb-4',
                weekday: 'font-normal',
            }}
            components={{
                Day: dayRenerder,
                Weekday: weekdayRenderer,
            }}
            {...rest}
        />
    )
}
