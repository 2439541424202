import { memo } from 'react'

import { AccountCircleOutlined } from '~/icons'

export const SurgeonCodeTag = memo(({ surgeonCode, prefix }: { surgeonCode: string; prefix?: string }) => {
    return (
        <span className="text-xs text-gray-600">
            <span className="flex w-fit items-center gap-[2px]">
                <AccountCircleOutlined className="h-4 w-4 text-black" /> {prefix ? `${prefix}:` : ''} {surgeonCode}
            </span>
        </span>
    )
})
