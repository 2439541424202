import { autoUpdate, flip, FloatingFocusManager, FloatingOverlay, offset, shift, useFloating } from '@floating-ui/react'
import clsx from 'clsx'
import { memo, ReactNode, useId } from 'react'

import { Cursor } from '../hooks'
import { Cell, toCellId } from '../utils'

export type ModalProps = (selectedCells: Cell[]) => ReactNode

type Props = {
    cursor: Cursor
    allCells: Cell[]

    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void

    modal: ModalProps
}

function ModalComponent({ cursor, allCells, isModalOpen, setIsModalOpen, modal }: Props) {
    const headingId = useId()
    const descriptionId = useId()

    const { refs, floatingStyles, context } = useFloating({
        onOpenChange: setIsModalOpen,
        placement: 'right-start',
        middleware: [offset({ alignmentAxis: 5, mainAxis: 5 }), shift({ padding: 30 }), flip()],
        elements: {
            reference: document.getElementById(toCellId({ rowIndex: cursor.row, colIndex: cursor.col })),
        },
        whileElementsMounted: (...args) =>
            autoUpdate(...args, {
                layoutShift: false,
                ancestorScroll: false,
            }),
    })

    if (isModalOpen) {
        return (
            <>
                <FloatingOverlay onClick={() => setIsModalOpen(false)} className="fixed inset-0 z-20 bg-black bg-opacity-10" lockScroll />
                <FloatingFocusManager context={context} closeOnFocusOut>
                    <div
                        ref={refs.setFloating}
                        aria-labelledby={headingId}
                        aria-describedby={descriptionId}
                        style={floatingStyles}
                        className={clsx('popover absolute z-30 h-fit overflow-auto')}
                    >
                        {modal(allCells)}
                    </div>
                </FloatingFocusManager>
            </>
        )
    }
}

export const Modal = memo(ModalComponent) as typeof ModalComponent
