import { immer } from 'zustand/middleware/immer'

import { PagePaths } from '~/app/routes'

import { Slice } from '../store'

type State = {
    activeViews: PagePaths
}

const initialState: State = {
    activeViews: {
        ADMIN_PANEL: '/admin-panel',
        PLANNING_PERIODS: '/periods',
        DOWNLOAD: '/download',
        MASTER_SURGERY_SCHEDULER: '/master-scheduler',
        OPERATIONAL_PLANNER: '/operational-planner/calendar',
        SURGEON_SCHEDULER: '/surgeon-scheduler/practitioners',
        WAITING_LIST: '/waiting-list/all',
    },
}

type Actions = {
    setActiveViews(activeViews: Partial<State['activeViews']>): void
}

export type AppSlice = {
    app: State & Actions
}

export const createAppSlice: Slice<AppSlice> = immer(set => ({
    app: {
        ...initialState,
        setActiveViews(activeViews: Partial<State['activeViews']>) {
            set(state => {
                state.app.activeViews = { ...state.app.activeViews, ...activeViews }
            })
        },
    },
}))
