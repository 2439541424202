import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { useState } from 'react'

import { Button } from '~/components/Button'

type Options = {
    title: string
    description: string
    cancelButtonLabel: string
    confirmButtonLabel: string
}

type ConfirmDialogState = Options & {
    resolve: (value: boolean) => void
}

export type ConfirmFn = (options: Options) => Promise<boolean>

type ConfirmDialogProps = {
    dialogProps: ConfirmDialogState | null
    setDialogProps: (value: ConfirmDialogState | null) => void
}

const ConfirmDialog = ({ dialogProps, setDialogProps }: ConfirmDialogProps) => {
    if (!dialogProps) return null

    const { title, description, confirmButtonLabel, cancelButtonLabel, resolve } = dialogProps

    const handleCancel = () => {
        setDialogProps(null)
        resolve(false)
    }

    const handleConfirm = () => {
        setDialogProps(null)
        resolve(true)
    }

    return (
        <AlertDialog.Root
            defaultOpen
            onOpenChange={value => {
                if (!value) handleCancel()
            }}
        >
            <AlertDialog.Overlay className="fixed inset-0 z-40 bg-black/20" />
            <AlertDialog.Content className="fixed left-1/2 top-1/2 z-50 max-h-[85vh] w-[90vw] max-w-[400px] -translate-x-1/2 -translate-y-1/2 transform">
                <div className="rounded-md bg-white shadow-lg">
                    <div className="p-3">
                        <AlertDialog.Title className="text-xl font-medium">{title}</AlertDialog.Title>
                        <AlertDialog.Description className="mt-1">{description}</AlertDialog.Description>
                    </div>
                    <div className="bottom-0 flex justify-end space-x-2 rounded-b bg-gray-200/20 p-3">
                        <Button onClick={handleCancel} link size="sm" color="primary" autoFocus>
                            {cancelButtonLabel}
                        </Button>
                        <Button onClick={handleConfirm} className="inline-flex items-center gap-x-2" size="sm" color="delete">
                            {confirmButtonLabel}
                        </Button>
                    </div>
                </div>
            </AlertDialog.Content>
        </AlertDialog.Root>
    )
}

export function useConfirmDialog() {
    const [dialogProps, setDialogProps] = useState<ConfirmDialogState | null>(null)

    const confirm: ConfirmFn = props => new Promise(resolve => setDialogProps({ ...props, resolve }))
    const Component = () => <ConfirmDialog dialogProps={dialogProps} setDialogProps={setDialogProps} />

    return [confirm, Component] as const
}
