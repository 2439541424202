import { useState } from 'react'

import { FilterDropdown, LocationFilter, PractitionerFilter, SearchText, Show } from '~/components'
import { selectOperationalPlannerFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

import { WeekdayFilter } from './WeekdayFilter'

type TimeoutId = ReturnType<typeof setTimeout> | null

export const Filters = () => {
    const isAvailabilityView = useStore(state => state.app.activeViews.OPERATIONAL_PLANNER) === '/operational-planner/availability'
    const needle = useStore(state => state.operationalPlanner.needle)
    const { set } = useStore(state => state.operationalPlanner.actions)

    const [rawNeedle, setRawNeedle] = useState(needle)
    const [needleTimeoutId, setNeedleTimeoutId] = useState<TimeoutId>(null)
    const [userEventTimeoutId, setUserEventTimeoutId] = useState<TimeoutId>(null)

    const { removeSingleFilter, emptyFilters } = useStore(state => state.appFilters.actions)
    const filters = useStore(selectOperationalPlannerFilters)

    function onNeedleChange(text: string) {
        setRawNeedle(text)

        if (needleTimeoutId) clearTimeout(needleTimeoutId)
        setNeedleTimeoutId(setTimeout(() => set({ needle: text }), 1))

        if (userEventTimeoutId) clearTimeout(userEventTimeoutId)
        setUserEventTimeoutId(
            setTimeout(() => {
                if (isAvailabilityView) {
                    void trackEvent('OP_SEARCHTEXT_AVAILABLE_TIMES_VIEW__SEARCH')
                }
            }, 1000)
        )
    }

    return (
        <div className="flex gap-2">
            <SearchText rawNeedle={rawNeedle} setNeedle={text => onNeedleChange(text)} />
            <FilterDropdown clearFilters={emptyFilters} onDisplayItemRemove={removeSingleFilter} displayItems={filters}>
                <Show condition={isAvailabilityView}>
                    <PractitionerFilter />
                </Show>
                <LocationFilter />
                <Show condition={isAvailabilityView}>
                    <WeekdayFilter />
                </Show>
            </FilterDropdown>
        </div>
    )
}
