import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar } from '~/components'

import { BarSearch } from './BarSearch'
import { Filters } from './Filters'

export const Header = () => (
    <Bar
        left={<ViewSwitcher pageKey="WAITING_LIST" />}
        right={
            <div className="flex gap-2">
                <BarSearch />
                <Filters />
            </div>
        }
    />
)
