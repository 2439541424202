import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

import { Schemas } from '~/clients/api-client'
import env from '~/env'
import { cluster } from '~/utils/array'

import * as imports from '../di-entity.api'
import { useStore } from '../store'

type WebSocketEvent = {
    entity_type: 'location' | 'department_location_assignment'
    id: number
    event_type: 'delete' | 'create' | 'update'
}

function isEntityEvent(object: unknown): object is WebSocketEvent {
    return typeof object === 'object' && object !== null && 'entity_type' in object && 'id' in object
}

type ExtractEntityTypes<SCHEMAS extends Schemas> = {
    [K in keyof SCHEMAS]: SCHEMAS[K] extends { entity_type: string } ? SCHEMAS[K]['entity_type'] : never
}[keyof SCHEMAS]

type EntityType = ExtractEntityTypes<Schemas>

const entityTypeImportMap = {
    age_group: imports.importAgeGroups,
    block_schedule: imports.importBlockSchedules,
    department_location_assignment: imports.importDepartmentLocationAssignments,
    department_practitioner_assignment: imports.importDepartmentPractitionerAssignments,
    department: imports.importDepartments,
    hospital_surgery_type_group_association: imports.importHospitalSurgeryTypeGroupAssociations,
    hospital_surgery_type: imports.importHospitalSurgeryTypes,
    location_schedule: imports.importLocationSchedules,
    location: imports.importLocations,
    practitioner_schedule_location: imports.importPractitionerScheduleLocations,
    practitioner_schedule_status: imports.importPractitionerScheduleStatuses,
    practitioner_schedule: imports.importPractitionerSchedules,
    practitioner: imports.importPractitioners,
    rule_definition: imports.importRuleDefinitions,
    section: imports.importSections,
    speciality: imports.importSpecialities,
    surgery_type_group_age_restriction: imports.importSurgeryTypeGroupAgeRestrictions,
    surgery_type_group_hierarchy: imports.importSurgeryTypeGroupHierarchies,
    surgery_type_group: imports.importSurgeryTypeGroups,
    surgery_metadatum: imports.importSurgeryMetadata,
} satisfies Partial<Record<EntityType, (typeof imports)[keyof typeof imports]>>

let socketStarted = false

export function startWebsocketBackgroundUpdates() {
    if (socketStarted) {
        return
    }

    socketStarted = true

    const webSocketUrl = env.VITE_API_BASE_URL.replace('https', 'wss') + '/ws'
    const socket = new WebSocket(webSocketUrl)
    const buffer: string[] = []
    let lastPing = Date.now()
    let lastPong = Date.now()
    let connectionCheckIntervalId: NodeJS.Timeout

    const processBuffer = debounce(function processBuffer() {
        if (buffer.length === 0) {
            return
        }

        const events = JSON.parse(`[${buffer.join(',')}]`) as unknown[]

        // Clear the buffer before processing the events to avoid
        // processing the same events multiple times
        buffer.length = 0

        const entityEvents = events.filter(isEntityEvent)
        const byEventType = groupBy(entityEvents, 'event_type')

        if (byEventType['delete'] && byEventType['delete'].length > 0) {
            useStore.getState().di.actions.removeEntitiesByWebsocketEvent(byEventType['delete'])
        }

        const entitiesToLoad = [...(byEventType['update'] ?? []), ...(byEventType['create'] ?? [])]

        if (entitiesToLoad.length > 0) {
            const entitiesByType = groupBy(entitiesToLoad, 'entity_type')

            const promises = map(entitiesByType, (events, entityType) => {
                const ids = events.map(event => event.id)
                const importCall = entityTypeImportMap[entityType as keyof typeof entityTypeImportMap]

                if (!importCall) {
                    console.error(`No import function found for entity type: ${entityType}`)
                    return Promise.resolve
                }
                return importCall({ 'id:in': cluster(ids) })
            })

            Promise.all(promises).catch(error => {
                console.error('Failed to update entities:', error)
            })
        }
    }, 50)

    socket.onerror = () => {
        // console.error('WebSocket error:', error)
    }

    socket.onmessage = event => {
        if (event.data === 'PONG') {
            lastPong = Date.now()
            return
        }

        buffer.push(event.data)
        processBuffer()
    }

    socket.onopen = () => {
        connectionCheckIntervalId = setInterval(() => {
            lastPing = Date.now()

            if (socket.OPEN) {
                socket.send('PING')

                if (lastPing - lastPong > 5000) {
                    console.error('WebSocket connection lost. Reconnecting...')
                    socket.close()
                }
            }
        }, 1000)
    }

    socket.onclose = () => {
        clearInterval(connectionCheckIntervalId)
    }

    // Failing to close the socket will cause multiple connections to be opened when hot reloading is triggered
    import.meta.hot?.on('vite:beforeUpdate', () => {
        socket.close()
    })
}
