import { Dayjs } from 'dayjs'

import { RuleValuesTags, Show } from '~/components'
import { HeroTooltip } from '~/components/HeroLibrary/HeroTooltip'
import { Location, selectGetBlockSchedules, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { rulesResolver } from '~/utils/blockEvaluation'
import { day, humanizeDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

import { SurgeonCodeTags } from './SurgeonCodeTags'

type Props = {
    date: Dayjs
    location: Location
}

export const ResolvedRule = ({ date, location }: Props) => {
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getBlockSchedules = useStore(selectGetBlockSchedules)

    const blockSchedule = getBlockSchedules.byDateAndLocationId(date, location.id)
    const resolvedRule = rulesResolver({ rules: blockSchedule?.rule_instance, getSurgeryTypeGroup })
    const editInfo = blockSchedule && `Sist endret av ${blockSchedule.updated_by} ${humanizeDate(day(blockSchedule.updated_at))}`

    return (
        <HeroTooltip tooltipContent={editInfo}>
            <div data-test={`block-schedule-${blockSchedule?.id}`} className="flex h-full flex-col justify-between gap-1">
                <div className="flex flex-col gap-1">
                    <Show condition={isNotNullish(blockSchedule)}>
                        <RuleValuesTags fullWidth size="md" resolvedConstraints={resolvedRule} />
                    </Show>
                </div>

                <SurgeonCodeTags date={date} location={location} />
            </div>
        </HeroTooltip>
    )
}
