import clsx from 'clsx'
import { ComponentProps, RefObject, useEffect, useRef, useState } from 'react'

import { useHandleEscKey } from '~/hooks/useHandleEscKey'
import { isNotNullish } from '~/utils/guards'

import { Show } from '../Show'
import { Spinner } from '../Spinner'
import { Body, BodyProps } from './Body'
import { Header, HeaderProps } from './Header'
import { Modal } from './Modal'
import { FooterProps, StickyFooter } from './StickyFooter'
import { Id } from './utils'

type TableType = JSX.IntrinsicElements['table']

type Props<Row extends { id: Id }, HeaderRow extends { id: Id }> = TableType & {
    header: HeaderProps<HeaderRow>
    extraHeaders?: HeaderProps<HeaderRow>[]
    body?: BodyProps<Row, HeaderRow>
    isLoading?: boolean
    modal?: ComponentProps<typeof Modal>['modal']
    footers?: FooterProps<HeaderRow>[]
    componentRef?: RefObject<HTMLDivElement>
}

export function Table<Row extends { id: Id }, HeaderRow extends { id: Id }>(props: Props<Row, HeaderRow>) {
    const { componentRef, header, extraHeaders, body, isLoading = false, modal, footers, className, ...rest } = props

    const localTableWrapperRef = useRef<HTMLDivElement>(null)
    const tableWrapperRef = componentRef ?? localTableWrapperRef
    const tableRef = useRef<HTMLTableElement>(null)
    const selectable = isNotNullish(modal)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedCells, setSelectedCells] = useState(() => new Set<string>())

    const hideModal = () => setIsModalOpen(false)
    const showModal = () => setIsModalOpen(true)
    useHandleEscKey(hideModal)

    useEffect(() => {
        const handleOutsideTableClick: EventListener = event => {
            const isInstanceOfAnyElement = event.target instanceof HTMLElement || event.target instanceof SVGElement

            if (isInstanceOfAnyElement && !tableWrapperRef.current?.contains(event.target)) {
                setSelectedCells(new Set([]))
                hideModal()
            }
        }

        document.addEventListener('click', handleOutsideTableClick)
        return () => document.removeEventListener('click', handleOutsideTableClick)
    })

    const handleCloseIconClick = () => {
        setSelectedCells(new Set([]))
        hideModal()
    }

    return (
        <div
            ref={tableWrapperRef}
            className={clsx('isolate max-h-full grow rounded border border-[#8391c3]', isLoading ? 'overflow-hidden' : 'overflow-auto', {
                'select-none': selectable,
            })}
        >
            <Show
                condition={!isLoading}
                fallback={
                    <div className="flex h-screen items-center justify-center">
                        <Spinner size="lg" />
                    </div>
                }
            >
                <table ref={tableRef} className={clsx(className, 'h-full min-w-full border-separate border-spacing-0')} {...rest}>
                    <thead className="sticky left-0 top-0 z-20">
                        <Header {...header} />
                        {extraHeaders &&
                            extraHeaders.map((extraHeader, index) => {
                                return <Header key={index} {...extraHeader} />
                            })}
                    </thead>

                    <tbody>
                        {body && (
                            <Body
                                {...body}
                                headerRow={header.row}
                                selectedCells={selectedCells}
                                setSelectedCells={setSelectedCells}
                                selectable={selectable}
                                hideModal={hideModal}
                                showModal={showModal}
                            />
                        )}

                        {footers && <StickyFooter tableRef={tableRef} headerRow={header.row} footers={footers} />}
                    </tbody>
                </table>

                {modal && (
                    <Modal
                        tableRef={tableRef}
                        handleCloseIconClick={handleCloseIconClick}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        modal={modal}
                        selectedCells={selectedCells}
                    />
                )}
            </Show>
        </div>
    )
}
