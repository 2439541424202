import { ReactNode } from 'react'

import { HeroSwitch } from '~/components'

type SwitchRowProps = {
    label: string
    children: ReactNode
    checked: boolean
    onCheckedChange: (checked: boolean) => void
}

export const SidebarSwitch = ({ label, children, checked, onCheckedChange }: SwitchRowProps) => (
    <div className="flex flex-row justify-between">
        <div className="flex gap-2">
            {children}
            <p>{label}</p>
        </div>
        <HeroSwitch checked={checked} onCheckedChange={onCheckedChange} />
    </div>
)
